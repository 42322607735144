export async function ApiCall(setDataAdress, setDataBody) {
  try {
    const response = await fetch(setDataAdress, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: setDataBody,
    });

    if (response.ok) {
      const responseJson = await response.json();

      if (responseJson.status) {
        // Handle Erfolgsfall
        //console.log('Erfolgreiche Serverantwort:', responseJson);
        return responseJson;
      } else {
        // Handle Fehlerfall
        //console.error('Fehlerhafte Serverantwort:', responseJson);
        return responseJson;
      }
    } else {
      // Handle Fehlerfall für ungültige Serverantwort
      console.error('Ungültige Serverantwort:', response);
      return response;

    }

    // Rückgabe des Response-Objekts
  } catch (error) {
    // Handle Netzwerkfehler oder Ausnahmen
    console.error('Fehler bei der Anfrage:', error);
    throw error; // Optional: Fehler weitergeben, damit sie in der Aufruferfunktion behandelt werden können
  }
}