import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav';


export default class NavTabCalendar extends Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }




    render() {

        if(this.props.onChangeCal) {
            
            const calendar = JSON.parse(sessionStorage.getItem('myCalendar'));

            return (
                <Nav justify variant="tabs" >
                    {
    
                        calendar.map((cal, i) => {
                            var calClass = 'text-bg-primary opacity-75';
                            var calDisabled = false;
                            if(this.props.calendarId === cal.id) {
                                calClass = 'text-bg-success opacity-75 active';
                                calDisabled = true;
                            }
                            return (
                                <Nav.Item key={i}>
                                    <Nav.Link
                                        className={calClass}
                                        onClick={this.props.onChangeCal}
                                        data-cal={cal.id}
                                        role="button"
                                        disabled={calDisabled}
                                    >
                                        {cal.lable}<br />{cal.name}
                                    </Nav.Link>
                                </Nav.Item>
                            )
                        })
    
                    }
            </Nav>   
            )
        } else if(this.props.onChangesDay) {
            const week = this.props.period;
            const weekkey = Object.keys(week);

            return (
                <Nav  justify variant="tabs">
                    {
    
                        weekkey.map((i) => {
                            let item = week[i];
                            //console.log(item);

                            var calClass = 'text-bg-primary opacity-75';
                            var calDisabled = false;

                            if(this.props.calDate === item.date) {
                                calClass = 'text-bg-success opacity-75 active';
                                calDisabled = true;
                            }
                            return (
                                <Nav.Item key={i}>
                                    <Nav.Link
                                        className={calClass}
                                        onClick={this.props.onChangesDay}
                                        data-date={item.date}
                                        role="button"
                                        disabled={calDisabled}

                                    >
                                        {item.day}, {item.long}
                                    </Nav.Link>
                                </Nav.Item>                            )
                        })
    
                    }
            </Nav>   
            )
        }


    }
}