import React, { Component } from 'react';
//import { Card, CardBody, Badge, ListGroup, ListGroupItem, Table } from 'reactstrap';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import ModalSpinner from '../ModalSpinner';
import ModalDate from '../../modals/ModalDate';
import ModalDateAdd from '../../modals/ModalDateAdd';
import NavTabCalendar from './components/NavTabCalendar';

export default class CalendarMonth extends Component {

    state = {
        isLoading: true,
        time: Date.now(),
        data: [],
        dates: { modal: "false", data: [] },
        fetchDate: null,
        calendarId: null,
        myService: null,
        myUser: null,
        myHash: '',
        pCode: '',
        token: null,
        newDate: false
    };

    constructor(props) {
        super(props);

        this.timeOut = null

        this.onChangeCal = this.onChangeCal.bind(this);


    }

    _fetchData = async () => {
        clearTimeout(this.timeOut);
        let responseJson;

        var body = "params[hash]=" + this.state.myHash;

        if(sessionStorage.getItem('myDate')) {
            body += `&params[date]=` + sessionStorage.getItem('myDate');
        }
        
        if(this.state.calendarId) {
            body += `&params[calendars]=` + this.state.calendarId;
        }
        body += "&params[storno]=no";

        body += "&limit=1";
        body += "&token=" + this.token;

        //console.log('CalendarWeek_fetchData', Date(), body)

        try {
            const response = await fetch(localStorage.getItem('server') + '/calendar/month/', {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },            
                method: 'POST',
                body: body,
            });
            //console.log('response', response.text());
            responseJson = await response.json();
            //console.log('responseJson', responseJson);
        } catch (error) {
           //console.log(new Date().toString(), 'Timeout error:', error);
        }



        if(responseJson.status && responseJson.data !== 'nochange') {
            this.setState({ 
                data: responseJson, 
                time: Date.now(), 
                myHash: responseJson.parameters.dataHash,
                isLoading: false,

            });
        } else if(!responseJson.status) {
            this.onClickExit();

        }
        this.timeOut = setTimeout(() => {
            this.updateTime();
        }, 10000);
        
    }

    handleDragOver(event) {
        //console.log("DateAdd DragOver to", event.target);
        //props.handleToTime(event);
        var el = document.getElementById(event.target.id);
        el.classList.add('text-bg-danger')
        event.preventDefault();
    }

    handleDragLeave(event) {
        var el = document.getElementById(event.target.id);
        el.classList.remove('text-bg-danger')
      }

    onChangeCal(event) {
        //console.log('onChangeCal', event);
        this.setState({ isLoading: true, calendarId: event.target.dataset.cal})
    }

    componentDidMount() {
        if(sessionStorage.getItem('myService')) {
            const response = sessionStorage.getItem('myService');
            const responseJson = JSON.parse(response);
            this.token = responseJson.Ergebnis;
            this.type = this.props.type;
            this.setState({
                myService: responseJson,
                token: responseJson.Ergebnis,
                fetchDate: this.props.fetchDate,
                calendarId: this.props.calendarid
            });
        } else {
            this.onClickExit();
        }
    }

    componentDidUpdate() {
        this._fetchData();
    }

    componentWillUnmount() {
       clearTimeout(this.timeOut);
    }

    updateTime() {
        //console.log('updateTime');
        this.setState({time: Date.now()});
    }

    render() {
        if(!this.state.isLoading) {
           //console.log(this.state);
            const action = this.state.data.action;
            const period = this.state.data.data.period;
            let calendar;
            if(this.state.calendarId) {
                calendar = this.state.data.data.calendar.find(elem => elem.id === this.state.calendarId);
            } else {
                calendar = this.state.data.data.calendar[0];

            }
            const perLine = 'calc(' + 100 / period.length + 'vh - (60px + ' + period.length * 13 + 'px ))';
            const xHours = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
            //const calId = 3;

            return(
                <Card>
                <NavTabCalendar onChangeCal={this.onChangeCal} calendarId={calendar.id} />
                <Card.Body className="p-0 m-0">
                <Table bordered style={{width:'100%', height:'100%'}}>
                    <thead>
                        <tr>
                            <th className="text-bg-primary opacity-75 text-center" style={{width: "15%"}}>
                                Montag
                            </th>
                            <th className="text-bg-primary opacity-75 text-center" style={{width: "15%"}}>
                                Dienstag
                            </th>
                            <th className="text-bg-primary opacity-75 text-center" style={{width: "15%"}}>
                                Mittwoch
                            </th>
                            <th className="text-bg-primary opacity-75 text-center" style={{width: "15%"}}>
                                Donnerstag
                            </th>
                            <th className="text-bg-primary opacity-75 text-center" style={{width: "15%"}}>
                                Freitag
                            </th>
                            <th className="text-bg-primary opacity-75 text-center" style={{width: "15%"}}>
                                Samstag
                            </th>
                            <th className="text-bg-warning opacity-75 text-center" style={{width: "10%"}}>
                                Sonntag
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {

                            // eslint-disable-next-line array-callback-return
                            period.map((iv, i) => {
                                return (
                                    <tr key={i} style={{height:perLine}}>
                                        {
                                            iv.map((value, key) => {
                                                let kw = '';
                                                let city = '';
                                                let daycity = {1: '',2: ''};
                                                let daytime = {1: null,2: null};
                                                if(
                                                    calendar.data
                                                    && calendar.data[value.date] 
                                                    && calendar.data[value.date][1] 
                                                    && calendar.data[value.date][1]['city']
                                                ) {
                                                    city += calendar.data[value.date][1]['city'];
                                                    daycity[1] = calendar.data[value.date][1]['city'] + ' (' + calendar.data[value.date][1]['dates_open'] + ')';
                                                    daytime[1] = 1;
                                                }
                                                if(calendar.data
                                                    && calendar.data[value.date] && calendar.data[value.date][2] && calendar.data[value.date][2]['city']) {
                                                    city += calendar.data[value.date][2]['city'];
                                                    daycity[2] = calendar.data[value.date][2]['city'] + ' (' + calendar.data[value.date][2]['dates_open'] + ')';
                                                    daytime[2] = 2;
                                                }

                                                if(calendar.dates[value.date]) {
                                                    let outMonth = 'm-0 p-0 opacity-50';
                                                    let outHead = 'text-bg-secondary opacity-75 p-2';

                                                    if(key === 0) {
                                                        kw = 'KW' + value.dayKW;
                                                    }
                                                    if(value.dayMonthNumber === action.thisMonth.number) {
                                                        outMonth = 'm-0 p-0';
                                                        outHead = 'text-bg-success opacity-75 p-2';
                                                    }

                                                    return (
                                                        <td key={key} className={outMonth}>
                                                            <div className={outHead} style={{height:'30px'}}><strong style={{color: '#000'}}>{kw}</strong>  <strong style={{float:'right'}}>{value.dayNumber}</strong></div>
                                                            <div className="p-2 border-bottom" style={{height:'30px'}}><i className="bi bi-sunrise"></i> {daycity[1]}</div>
                                                            <div className="p-2 border-bottom" style={{height:'30px'}}><i className="bi bi-sunset"></i> {daycity[2]}</div>
                                                            <ListGroup className="m-0 p-0" style={{height: perLine, overflowX:'auto', display:'block'}}>
                                                            {
                                                                // eslint-disable-next-line array-callback-return
                                                                xHours.map((ix, x) => {
                                                                    let aColor = '';
                                                                    if(calendar.dates[value.date][ix] && calendar.dates[value.date][ix]['id']) {

                                                                        aColor = 'text-bg-' + calendar.dates[value.date][ix]['color'];

                                                                        return(
                                                                            <ModalDate key={x} modtyp="calendarMonth" element={calendar.dates[value.date][ix]} aColor={aColor} start={calendar.dates[value.date][ix]['start']} name={calendar.dates[value.date][ix]['name']} />
                                                                            /*<ListGroupItem key={x} href="#" tag="a" className={aColor}
                                                                                >{calendar.dates[value.date][ix]['start']} {calendar.dates[value.date][ix]['name']}
                                                                            </ListGroupItem>*/
                                                                        )
                                                                    } else if(x < 18 && daytime[1] && calendar.dates[value.date][ix] && this.state.newDate && calendar.dates[value.date][ix] !== 'none') {
                                                                        return(
                                                                            <ListGroup.Item 
                                                                                onDragOver={this.handleDragOver}
                                                                                onDragLeave={this.handleDragLeave}
                                                                                id={i + 'X' + value.dayNumber + 'X' + x} key={x}>{calendar.dates[value.date][ix]}</ListGroup.Item>
                                                                        )
                                                                    } else if(x > 17 && daytime[2] && calendar.dates[value.date][ix] && this.state.newDate && calendar.dates[value.date][ix] !== 'none') {
                                                                        return(
                                                                            <ModalDateAdd key={x} modtyp="calendarMonth" id={i + 'X' + value.dayNumber + 'X' + x} calId={calendar.id} calTime={calendar.dates[value.date][ix]} />
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <div style={{backgroundColor: 'grey'}}></div>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            </ListGroup>
                                                        </td>
                                                    )
                                                } else {
                                                    return (
                                                        <td>
                                                            <Badge pill color="primary" className="mb-2">{value.dayNumber}</Badge> {city}
                                                        </td>
                                                    )                                                    
                                                }

                                            })    
                                        }
                                    </tr>
                                )

                            })
                        }
                    </tbody>
                </Table>
                </Card.Body>
                </Card>
            );
        } else {
            return (
                <ModalSpinner isloading={true} />
            );
        }
    }
}