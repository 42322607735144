import { Component } from "react";
//import { Col, Container, Card, CardHeader, CardBody, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import CardHeader from 'react-bootstrap/CardHeader';
import CardBody from 'react-bootstrap/CardBody';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';



export default class ClientSetup extends Component {

    state = {
        pCode: '',
        pCodeIsFail: false,
        pCodeFail: 'Dieser Personalcode ist nicht gültig!',
        clientId: '',
        clientIdIsFail: false,
        clientIdFail: 'Diese Bezeichnung ist falsch oder bereits vergeben!',
        setDataAdress: '',
        setDataBody: '',
        browser: null,
        browserIsFail: false,
        browserFail: 'Bitte den Browser oder das Gerät wählen!',
        isFetchData: false,
        devApp: true,
        uuid: null,
        error: ''
    }

    constructor(props) {
        super(props);

        this.browser = { 
                        1: 'Chrome',
                        2: 'Edge',
                        3: 'Safari',
                        4: 'iPad WebApp',
                        5: 'iPhone WebApp',
                        6: 'Firefox',
                    };
        
        this.myService = null;
        this.fetchAdress = null;
        this.fetchBody = null;
        this.pcode = null;
        this.token = null;

        this.handleChange = this.handleChange.bind(this);
        this.saveClient = this.saveClient.bind(this);
        this.setAppState = this.setAppState.bind(this);
    }

    _setData = async () => {
       //console.log(this.state.setDataAdress, this.state.setDataBody)

        const response = await fetch(this.state.setDataAdress, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },            
            method: 'POST',
            body: this.state.setDataBody,
        });
        //console.log(response.text());
        // eslint-disable-next-line no-unused-vars
        const responseJson = await response.json();
       //console.log(responseJson);

        if(responseJson.status && responseJson.data.clientSet) {
            localStorage.setItem('UUID', this.state.uuid);
            localStorage.setItem('client', this.state.clientId);
            sessionStorage.clear();
            window.location.reload(false);
        } else if(responseJson.status) {
            //console.log("_setDataFalse", responseJson);
            this.setState({
                clientIdIsFail: true,
                clientIdFail: 'Die Kombination ' + this.state.clientId + ' ' + this.browser[this.state.browser] + ' ist bereits vergeben!',
                pCode: '',
                error: responseJson.data.error,
                isFetchData: false
            });
        } else {
            //console.log("_setDataFalse",responseJson);
            this.setState({
                pCode: '',
                error: 'Es ist ein unbekannter Fehler aufgetreten.'.responseJson.data.error,
                isFetchData: false
            });
            
        }

    };

    handleChange(event) {
        //console.log(event.target)
        this.setState({
            [event.target.name]: event.target.value
        });
    }



    saveClient(event) {
        if(this.state.clientId.length > 4 && this.state.browser !== null && event.target.value.length === 7) {
            var fetchBody= '';
            fetchBody = "code=" + event.target.value;
            fetchBody += "&params[clientId]=" + this.state.clientId;
            fetchBody += "&params[browser]=" + this.state.browser;
            fetchBody += "&params[uuid]=" + this.state.uuid;
            this.setState({
                [event.target.name]: '',
                setDataAdress: localStorage.getItem('server') + '/client/add',
                setDataBody: fetchBody,
                isFetchData: true

            });
        } else if(this.state.clientId.length < 5 && this.state.browser === null) {
            //console.log('back 1 c', this.state.clientId.length, 'b', this.state.browser);
            this.setState({
                [event.target.name]: '',
                clientIdIsFail: true,
                clientIdFail: 'Diese Bezeichnung ist ungültig!',
                browserIsFail: true
            });
        } else if(this.state.clientId.length < 5 && this.state.browser !== null) {
            //console.log('back 2', this.state.clientId.length, this.state.browser);
            this.setState({
                [event.target.name]: '',
                clientIdIsFail: true,
                clientIdFail: 'Diese Bezeichnung ist ungültig!',
                browserIsFail: false
            });
        } else if(this.state.clientId.length > 4 && this.state.browser === null) {
            //console.log('back 3', this.state.clientId.length, this.state.browser);
            this.setState({
                [event.target.name]: '',
                clientIdIsFail: false,
                browserIsFail: true
            });
        } else if(this.state.clientId.length > 4 && this.state.browser !== null) {
            //console.log('back 4', this.state.clientId.length, this.state.browser);
            this.setState({
                [event.target.name]: event.target.value,
                clientIdIsFail: false,
                browserIsFail: false
            });
        } else {
            //console.log('back 5', this.state.clientId.length, this.state.browser);
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    }

    setAppState() {
        //console.log('setAppState', this.state.devApp,localStorage.getItem('dev'));
        if(this.state.devApp) {
            localStorage.setItem('server', 'https://d.24-expert.de/service/v1.0');
            localStorage.setItem('dev', false);
            this.setState({
                devApp: false
            });
        } else {
            localStorage.setItem('server', 'https://d.entwicklung.24-expert.de/service/v1.0');
            localStorage.setItem('dev', true);
            this.setState({
                devApp: true
            });
        }

    }

    componentDidMount() {
        var client = '';
        var dev = true;
        var uuid = null;
        if(localStorage.getItem('dev') === 'false') {
            dev = false;
        }
        if(!localStorage.getItem('UUID')) {
            uuid = window.crypto.randomUUID();
            //localStorage.setItem('UUID', uuid);

        } else {
            uuid = localStorage.getItem('UUID')
        }
        //let uuid = self.crypto.randomUUID();
        if(localStorage.getItem('client')) {
            client = localStorage.getItem('client');
        } 
        if(window.innerWidth < 1025) {
            localStorage.setItem('clienttype', 'mobile');
        } else {
            localStorage.setItem('clienttype', 'desktop');
        }

        this.setState({
            clientId: client,
            devApp: dev,
            uuid: uuid
        });
    }

    componentDidUpdate() {
        if(this.state.isFetchData) {
            this._setData();
        }
    }

    render() {
        //console.log('dev', this.state.devApp, localStorage.getItem('dev'))
        var clientName = 'Geräte Name';
        if(localStorage.getItem('clienttype') === 'mobile') {
            clientName = 'KFZ Kenzeichen';
        } else if(localStorage.getItem('clienttype') === 'desktop') {
            clientName = 'Rechner Name';
        }


        return(
            <Container>
                <Card
                className="position-absolute top-50 start-50 translate-middle"
                style={{
                            width: '30rem'
                        }}>
                    <CardHeader>Client Setup</CardHeader>
                    <CardBody>
                        <Form>
                                <Form.Group as={Row} className="mb-3" controlId="clientId">
                                    <Form.Label column sm={4}>{clientName}</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            onChange={this.handleChange}
                                            value={this.state.clientId}
                                            name="clientId"
                                            type="text"
                                            autoComplete="off"
                                            invalid={this.state.clientIdIsFail}
                                        />
                                        <Form.Control.Feedback>
                                            {this.state.clientIdFail}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>       


                                <Form.Group as={Row} className="mb-3" controlId="browser">
                                    <Form.Label column sm={4}>Client  Typ</Form.Label>
                                    <Col sm={8}>
                                        <Form.Select
                                            onChange={this.handleChange}
                                            name="browser"
                                            type="select"
                                            invalid={this.state.browserIsFail}
                                        >
                                            <option value={null}>Bitte wählen</option>
                                            <option value='1'>Chrome</option>
                                            <option value='2'>Edge</option>
                                            <option value='3'>Safari</option>
                                            <option value='6'>Firefox</option>
                                            <option value='4'>IPad WebApp</option>
                                            <option value='5'>IPhone WebApp</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {this.state.browserFail}
                                        </Form.Control.Feedback>  
                                    </Col>
                                </Form.Group>   

                                <Form.Group as={Row} className="mb-3" controlId="pCode">
                                    <Form.Label column sm={4}>Personal Code</Form.Label>
                                    <Col sm={8}>
                                    <Form.Control 
                                        onChange={this.saveClient}
                                        value={this.state.pCode}
                                        name="pCode"
                                        type="password"
                                        autoComplete="off"
                                        invalid={this.state.pCodeIsFail}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {this.state.browserFail}
                                    </Form.Control.Feedback>  
                                    </Col>

                                </Form.Group>                                


                            <FormGroup as={Row} controlId="Develop">
                                <Col sm={8}>
                                    <Form.Check 
                                        type="switch"
                                        onChange={this.setAppState}
                                        checked={this.state.devApp}
                                        label="Develop"

                                    />
                                </Col>
                            </FormGroup>    
                            <div className='row mt-3'>
                                <div className='col-12 text-black-50 text-center'>
                                    {this.state.uuid} 
                                </div>
                            </div>               
                        </Form>
                    </CardBody>
                </Card>
            </Container>
            )
    }



}