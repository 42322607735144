import React, { Component } from 'react';
import Card from "react-bootstrap/Card";
import CardHeader from "react-bootstrap/CardHeader";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

export default class TerminList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            time: Date.now(),
            response: [],
            count: 0,
            myHash: null,
            token: null,

        };
        this.nocode = '';
        this.timeOut = null;
        this.daytime = {1:'Vorm.', 2:'Nachm.'};
        this.countLoad = 0;

        this.goSubmit = this.goSubmit.bind(this);


        this.onClickFree = this.onClickFree.bind(this);
    }

    _fetchData = async () => {
        this.countLoad += 1;
        clearTimeout(this.timeOut);
        var body = "token=" + this.token;

        if(this.state.fetchDate) {
            body += `&params[date]=` + this.state.fetchDate
        }
        body += "&params[hash]=" + this.state.myHash;

        const response = await fetch(localStorage.getItem('server') + '/dates/open', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },            
            method: 'POST',
            body: body,
        });

        //console.log(body);
        
        //console.log(response.text());
        const responseJson = await response.json();
       //console.log(this.countLoad + 'TerminList_fetchData', responseJson);

        if(responseJson.data !== 'nochange') {
            //console.log('TerminList_hash', responseJson)

            this.setState({ 
                response: responseJson, 
                time: Date.now(), 
                isLoading: true, 
                myHash: responseJson.parameters.dataHash
            });
        }
        this.timeOut = setTimeout(() => {
            this.updateTime();
        }, 30000);
        
    };  
    
    _setData = async () => {
        clearTimeout(this.timeOut);

        //console.log(this.setDataAdress, this.setDataBody)

        const response = await fetch(this.setDataAdress, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },            
            method: 'POST',
            body: this.setDataBody,
        });
        //console.log(response.text());
        // eslint-disable-next-line no-unused-vars
        const responseJson = await response.json();

        //console.log("_setData",responseJson);
        this.updateTime();

    };

    goSubmit(event) {
        event.preventDefault();

        this.setDataAdress = localStorage.getItem('server') + '/dates/update';

        this.setDataBody = "params[id]=" + event.target.dataset.date_id;
        this.setDataBody += "&params[order_id]=" +  event.target.dataset.order_id;
        this.setDataBody += "&params[info]=";

        this.setDataBody += "&params[function]=STATUS";
        this.setDataBody += "&params[status]=10";

        this.setDataBody += "&params[start]=" + event.target.value;
        this.setDataBody += "&params[runtime]=" + event.target.dataset.runtime;

        this.setDataBody += "&token=" + this.state.token;

        //console.log(this.setDataBody);
        this._setData();
    }

    componentDidUpdate() {
        this._fetchData();
    }

    updateTime() {
        this.setState({time: Date.now()});
    }

    componentWillUnmount() {
        clearTimeout(this.timeOut);
     }

    componentDidMount() {
        //console.log("TerminList");
        if(sessionStorage.getItem('myService')) {
            const response = sessionStorage.getItem('myService');
            const responseJson = JSON.parse(response);
            this.token = responseJson.Ergebnis;
            this.setState({
                token: responseJson.Ergebnis,
            });
        }        
    }    

    onClickFree(event) {
        //console.log('test', event.target.value, event.target.dataset.date_id);
    }

    render() {
        var termList;

        if(this.state.isLoading) {
            
            const d = this.state.response;
            var table_content = [];
            var calendar = d.data.calendar;
            if(d.data && d.data.dates) {
                table_content = d.data.dates;
            }
            //console.log(d);

            const rAbteilung = ['6','8','11'];

            const myservice = JSON.parse(sessionStorage.getItem('myService'));

            var inputTime = true;
            if(rAbteilung.includes(myservice['MitarbeiterAbteilung'])) {
                inputTime = false;
            }

            termList = table_content.map((element, index) => {
                return (
                    //{this.daytime[daytime.daytime]}
                    <tr key={index}>
                        
                        <td>#{element.date_id}</td>
                        <td>{element.debitor_firstname} {element.debitor_name}</td>
                        <td>{element.debitor_address}, {element.debitor_zipcode} {element.debitor_city}</td>
                        <td>{element.description}</td>
                        <td className='text-center'>{calendar[element.calendar].lable}</td>
                        <td>{element.date}</td>
                        <td>{this.daytime[element.daytime]}</td>
                        <td className='text-center'>{element.runtime}</td>
                        <td>
                        <Form.Select
                            size="sm"
                            id="exampleSelect"
                            name="start"
                            type="select"
                            onChange={this.goSubmit} 
                            data-order_id={element.order_id}
                            data-date_id={element.date_id}
                            data-runtime={element.runtime}
                            disabled={inputTime}
                            value=""
                        >
                            <option value=''></option>
                            {

                                calendar[element.calendar].data[element.order_date][element.daytime].map((sel, indexs) => {
                                    return (
                                        <option key={indexs} value={sel[1]} disabled={sel[2]}>{sel[1]}</option>
                                    )                                        
                                })
                            
                            }
                        </Form.Select>
                        </td>
                        

                    </tr>
                )                    
            

            });

        } else {
            termList =  <tr>
                            <td>
                                <Spinner >
                                Loading...
                                </Spinner>
                            </td>
                        </tr>
            
        }

            return (
                <Card>
                    <CardHeader className='h3'>
                        Unzugeordnete Termine
                    </CardHeader>
                    <Table hover>
                        <thead>
                            <tr>
                                <th style={{width: '5%'}}>#</th>
                                <th style={{width: '15%'}}>Kunde</th>
                                <th style={{width: '20%'}}>Adresse</th>
                                <th style={{width: '40%'}}>Auftrag</th>
                                <th className='text-center' style={{width: '5%'}}>Kalender</th>
                                <th style={{width: '5%'}} colSpan={2}>Datum</th>
                                <th className='text-center' style={{width: '5%'}}>Min</th>
                                <th className='text-center' style={{width: '5%'}}>Startzeit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {termList}
                        </tbody>
                    </Table>
                </Card>
            )


    }


}

