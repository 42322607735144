import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import NavLink from 'react-bootstrap/NavLink';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import CardHeader from 'react-bootstrap/CardHeader';
import CardBody from 'react-bootstrap/CardBody';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import ExModalDateAdd from '../modals/ModalDateAdd'
import { ApiCall } from '../functions/apicall';



export default class TerminSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            time: Date.now(),
            errorResponse: false,
            response: [],
            count: 2,
            myHash: null,
            hash: null,
            city: '',
            devApp: true
        };
        this.timeOut = null;
        this.errorResponse = false;

        this.nocode = '';
        this.colorData = this.colorData.bind(this);
        this.onSelectCity = this.onSelectCity.bind(this);
        this.onSetCount = this.onSetCount.bind(this);
        this.setAppState = this.setAppState.bind(this);
    }

    x_fetchData = async () => {
        clearTimeout(this.timeOut);
        this.errorResponse = true;

        const oldData = this.state.response;

        var body = 'token=999988887777666655554444333322221111';

        if(this.state.fetchDate) {
            body += `&params[date]=` + this.state.fetchDate;
        }
        body += `&params[count]=` + this.state.count;
        body += "&params[hash]=" + this.state.myHash;

        //console.log('TerminPlaner_fetchData', Date(), body)

        let responseJson;

        try {
            const response = await fetch(localStorage.getItem('server') + '/calendar/open', {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },            
                method: 'POST',
                body: body,
            });
            responseJson = await response.json();
            this.errorResponse = false;
        } catch (e) {
            responseJson = oldData;
            this.errorResponse = true;
            //console.log('There was an error', e);

        }

        //console.log(response.text());
        


        //console.log(responseJson);

        if(responseJson.status && responseJson.data !== 'nochange') {
            //console.log('!== nochange', this.state.errorResponse)

            this.setState({ 
                response: responseJson, 
                time: Date.now(), 
                myHash: responseJson.parameters.dataHash,
                isLoading: false,
                errorResponse: this.errorResponse 

            });
        } else if(responseJson.status && responseJson.data === 'nochange') {

            //console.log('=== nochange', this.state.errorResponse)

        }
        this.timeOut = setTimeout(() => {
            this.updateTime();
        }, 30000);
    };

    _fetchData() {
        clearTimeout(this.timeOut);
        this.errorResponse = true;

        const oldData = this.state.response;

        var setDataBody = 'token=999988887777666655554444333322221111';

        if(this.state.fetchDate) {
            setDataBody += `&params[date]=` + this.state.fetchDate;
        }
        ///list=yes
        setDataBody += `&list=yes`;
        setDataBody += `&params[count]=` + this.state.count;
        setDataBody += "&params[hash]=" + this.state.myHash;      

        // Verwenden Sie die Funktion
        const setDataAdress = localStorage.getItem('server') + '/calendar/open';
       //console.log("Home _fetchData body", setDataBody);


        ApiCall(setDataAdress, setDataBody)
        .then((response) => {
            // Verarbeiten Sie den Response hier, wenn gewünscht
            this.errorResponse = false;

           //console.log('call', response);
            if(response.status && response.data !== 'nochange') {
               //console.log('!== nochange', this.state.errorResponse)
                sessionStorage.setItem('myProductArea', JSON.stringify(response.product.area));
                sessionStorage.setItem('myProductInfo', JSON.stringify(response.product.info));       

                this.setState({ 
                    response: response, 
                    time: Date.now(), 
                    myHash: response.parameters.dataHash,
                    isLoading: false,
                    errorResponse: this.errorResponse 

                });
            }
        })
        .catch((error) => {
            // Behandeln Sie Fehler, die von der Funktion ausgelöst werden
            this.errorResponse = true;

            this.setState({ 
                response: oldData, 
                time: Date.now(), 
                myHash: this.state.fetchDate,
                isLoading: false,
                errorResponse: this.errorResponse 

            });
            //console.log('call', error);
        });
        this.timeOut = setTimeout(() => {
            this.updateTime();
        }, 30000);
    }

    onSelectCity(event) {
        //console.log(event.target.value);
        this.setState({ city: event.target.value, time: Date.now()});
    }
    
    onSetCount(event) {
        //console.log(event.target.value);
        this.setState({ count: event.target.value, time: Date.now()});
    }


    updateTime() {
        //console.log('updateTime');
        this.setState({
            time: Date.now(),
            errorResponse: this.errorResponse

        });
    }

    setAppState() {
        //console.log(this.state.devApp);
        if(this.state.devApp) {
            localStorage.setItem('server', 'https://d.24-expert.de/service/v1.0');
            localStorage.setItem('dev', false);
            this.setState({
                devApp: false
            });
        } else {
            localStorage.setItem('server', 'https://d.entwicklung.24-expert.de/service/v1.0');
            localStorage.setItem('dev', true);
            this.setState({
                devApp: true
            });
        }

    }

    updateErrorResponse() {
        //console.log('updateErrorResponse');
        this.setState({
            errorResponse: true
        });
    }

    componentDidUpdate() {
        //console.log('componentDidUpdate');
        this._fetchData();
    }

    componentDidMount() {
        var dev = true;
        if(localStorage.getItem('dev') === 'false') {
            dev = false;
        }
        var week = 2;
        if(this.props.week) {
            week = this.props.week;
        }
        if(localStorage.getItem('clienttype') === 'mobile') {
            week = 1;
        }

        this.setState({
            devApp: dev,
            count: week,
        });
    }

    colorData(open) {
        if(open <= 0) {
            return 'bg-danger'
        } else if(open <= 60) {
            return 'bg-warning'
        } else {
            return 'bg-success'
        }
    }


    render() {

        const daytime = [1,2];
        const daytimename = ['', 'Vormittag', 'Nachmittag']
        //console.log(this.state.response)

        var currentTime = new Date(this.state.time);
    
        var nowtime = currentTime.getHours().toString().padStart(2, '0') + ":" + currentTime.getMinutes().toString().padStart(2, '0');

        var devText = '';
        var devClass = '';
        if(localStorage.getItem('dev') === 'true') {
            devText = 'TESTSYSTEM';
            devClass = 'bg-danger';
        }


        if(this.state.response.data) {
            let dataPeriod = this.state.response.data.period;
            let dataCalendar = this.state.response.data.calendar;
            //console.log('TerminSearch', dataPeriod, dataCalendar);
            var cityDisabled = false;
            if(this.state.city === '') {
                cityDisabled = true;
            }
            return (
                <Container fluid>
                    <Alert variant="danger" show={this.state.errorResponse} className="position-absolute start-50 translate-middle" style={{top: '120px', zIndex: '1080'}}
 >                      <h3><Spinner color="danger" /> Verbindungsfehler!</h3>
                        Verbindung verloren. Die daten werden nicht aktualisiert.<br />
                        Wiederaufbau wird versucht.
                    </Alert>
                    <Card>
                        <CardHeader id="day" className={devClass}>
                        <div className="row">
                            <div className="col">
                            <h3>{nowtime} Terminübersicht {devText}</h3>
                            </div>
                            <div className="col-3">

                            <Form.Select 
                                aria-label="Default select example" 
                                onChange={this.onSelectCity}
                                value={this.state.city}
                                name="city"
                            >
                                <option>Bitte Gebiet der Kundenadresse wählen</option>
                                <option value='Ahaus'>Gebiet Ahaus</option>
                                <option value='Borken'>Gebiet Borken</option>
                                <option value='Coesfeld'>Gebiet Coesfeld</option>
                            </Form.Select>

                            </div>
                            <div className="col-2">
                            <Form.Select 
                                aria-label="Default select example" 
                                onChange={this.onSetCount}
                                value={this.state.count}
                                name='wochen'
                            >
                                <option value='1'>1 Woche</option>
                                <option value='2'>2 Wochen</option>
                                <option value='3'>3 Wochen</option>
                                <option value='4'>4 Wochen</option>
                                <option value='5'>5 Wochen</option>
                                <option value='6'>6 Wochen</option>
                            </Form.Select>                                

                            </div>
                            <div className="col-1">
                                <NavLink
                                    active
                                    href="https://service.24-expert.de"
                                    value="CalendarDay"
                                    className="btn btn-primary p-1 m-1"
                                    target="servicePlaner"
                                >
                                    ServicePlaner
                                </NavLink>
                            </div>

                        </div>
                            

                        </CardHeader>
                        <CardBody>
                        <Alert variant="warning" show={cityDisabled}>
                            <strong>Hinweis:</strong> Bitte Gebiet wählen! Termine können erst nach der Gebietswahl eingetragen werden.
                        </Alert>
                            <Table  striped bordered hover>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        {
                                            // eslint-disable-next-line array-callback-return
                                            dataPeriod.map((element, index) => {
                                                if(element.day === '7') {
                                                    return (
                                                        <td key={index} className="text-center bg-warning bk-opacity-25 text-black" >{element.dayNameShort}<br />{element.dayShort}</td>
                                                    )
                                                } else {
                                                    return (
                                                        <td key={index} className="text-center">{element.dayNameShort}<br />{element.dayShort}</td>
                                                    )
                                                }

                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                            
                                        // eslint-disable-next-line array-callback-return
                                        dataCalendar.map((element, index) => {

                                            return (

                                            daytime.map((dt, idt) => {
                                                
                                                var trLable = '';
                                                if(dt === 1) {
                                                    trLable = <td rowSpan="2">{element.lable}<br />{element.name}</td>;
                                                }

                                                return (
                                                    <tr key={idt}>
                                                        {trLable}
                                                        <td style={{
                                                            paddingTop: 1,
                                                            paddingBottom: 2
                                                        }}>{daytimename[dt]}</td>
    
                                                        {
                                                            // eslint-disable-next-line array-callback-return
                                                            dataPeriod.map((pelement, pindex) => {
    
                                                                    if(element.data && element.data[pelement.date] && element.data[pelement.date][dt] && element.data[pelement.date][dt].date && (element.data[pelement.date][dt].dates_open * 1) <= 30  && !element.data[pelement.date][dt].closed) {
                                                                        return (
                                                                            <td key={pindex} className="bg-danger"
                                                                                style={{
                                                                                    padding: 0
                                                                                    
                                                                                }}></td>
                                                                        )
                                                                    } else if(element.data && element.data[pelement.date] && element.data[pelement.date][dt] && element.data[pelement.date][dt].date && (!this.state.city || element['data'][pelement.date][dt].city === this.state.city || element['data'][pelement.date][dt].city === '')  && !element.data[pelement.date][dt].closed) {

                                                                        //console.log('1', element, pelement);
                                                                        return (
                                                                                <ExModalDateAdd 
                                                                                    key={pindex}
                                                                                    type='notime'
                                                                                    usedtime={element.data[pelement.date][dt].dates_open}
                                                                                    maxTime={element.data[pelement.date][dt].dates_open}
                                                                                    maxDayTime={element.data[pelement.date][0].dates_open}
                                                                                    element={element} 
                                                                                    date={pelement.date} 
                                                                                    calId={element.id} 
                                                                                    daytime={dt} 
                                                                                    rowStart={1} 
                                                                                    onDatesInsert={this.props.onDatesInsert}
                                                                                    handleToTime={this.handleToTime} 
                                                                                    disabled={cityDisabled}
                                                                                />
                                                                        )
                                                                    } else if( element.data && element.data[pelement.date] && element.data[pelement.date][dt] && element.data[pelement.date][dt].date && element.data[pelement.date][dt].closed === true ) {
                                                                        //console.log(element['data'][pelement.date][dt].user_primary, element['data'][pelement.date][dt].dates_open);
                                                                        const xtime = 100 - element['data'][pelement.date][dt].dates_open / 240 * 100 + '%';
                                                                        return (
                                                                            <td key={pindex} style={{padding: "0px"}}>
                                                                                <div className="bg-success position-relative progress" style={{borderRadius: 'unset', height: '23px', textAlign: 'right'}}>
                                                                                    <div className="progress-bar progress-bar-striped bg-primary" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="240" style={{backgroundColor: 'red', width: xtime, }}>
                                                                                        <small className="justify-content-center d-flex position-absolute w-100">{element['data'][pelement.date][dt].city}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        ) 
                                                                    } else if(pelement.day === '7') {
                                                                        return (
                                                                            <td key={pindex} className="bg-warning opacity-25" 
                                                                                style={{
                                                                                    padding: 0
                                                                                    
                                                                                }}></td>
                                                                        ) 
                                                                    } else  {
                                                                        return (
                                                                            <td key={pindex} className="bg-secondary" 
                                                                                style={{
                                                                                    padding: 0
                                                                                    
                                                                                }}></td>
                                                                        ) 
                                                                    }
    
                                                            })
                                                        }
                                                    </tr>

                                                )
    
                                            })
                                            )
                                        })

                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Container>
            )
        } else {
            return null;
        }
    }
}

export class ExDatesItemsSplit extends Component {

    constructor(props) {
        super(props);

        this.onClickModal = this.onClickModal.bind(this);
        this.offsetHeight = 34;
    }    
}


/*
<Progress
                                                                        max="240"
                                                                        color="success"
                                                                        barStyle={{
                                                                            backgroundColor: 'red'
                                                                        }}
                                                                        style={{
                                                                            borderRadius: 'unset',
                                                                            height: '23px',
                                                                            backgroundColor: 'red',
                                                                            textAlign: 'left'
                                                                        }}
                                                                    value={element.data[pelement.date][1].dates_open}
                                                                  ></Progress>
                                                                  */