import React, { useState } from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,

} from "@vis.gl/react-google-maps";
import Card from "react-bootstrap/Card";
import CardBody from "react-bootstrap/Card";
import CardHeader from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import ListGroupItemHeading from "react-bootstrap/ListGroup";
import ListGroupItemText from "react-bootstrap/ListGroup";


export default function Intro(props) {
  //const [open, setOpen] = useState(false);

  const NEXT_PUBLIC_GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const NEXT_PUBLIC_MAP_ID = process.env.REACT_APP_MAP_ID;

  const itemNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];


  const dataArray = [];
  const lngArray = [];  
  const latArray = [];
  var stateCount = [];
  itemNumber.forEach(item => {
    // Access individual data from element and add it to the dataArray
    //dataArray.push(element.data);
    if(props.itemDates[item] !== undefined && typeof props.itemDates[item] !== 'string')  {
      //console.log(props.itemDates[item]);
      dataArray.push(props.itemDates[item]);
      latArray.push(props.itemDates[item].geo.lat);
      lngArray.push(props.itemDates[item].geo.lng);
      stateCount.push("rgba(158,226,230,.6)");
    }

  });
  // Gibt den mittelwert von latArray und lngArray aus
  const latSum = latArray.reduce((acc, lat) => acc + lat, 0);
  const lngSum = lngArray.reduce((acc, lng) => acc + lng, 0);
  const latAverage = latSum / latArray.length;
  const lngAverage = lngSum / lngArray.length;
  
  const [position, setPosition] = useState({ lat: latAverage, lng: lngAverage });
  const [poscolor, setColor] = useState( stateCount );

  //console.log("Diff lat:", (Math.max(...latArray) - Math.min(...latArray)));
  //console.log("Diff lng:", (Math.max(...lngArray) - Math.min(...lngArray)));


  var mathZoom = 0;
  if( (Math.max(...latArray) - Math.min(...latArray)) > 0.1 || (Math.max(...lngArray) - Math.min(...lngArray)) > 0.1) {
    mathZoom =11;
  } else {
    mathZoom =12;
  }

  const [zoom, setZoom] = useState(mathZoom);


  const CustomMarker = ({ zeit, text, poscolor }) => (
    <div className='plan plan-follow'  style={{ position: 'relative'}}>
            <div
              style={{
                backgroundColor: poscolor,
                border: '1px solid #ccc',
                borderRadius: '5px',
                padding: '5px',
                minWidth: '100px',
                textAlign: 'center'
              }}
            >
              {zeit}<br />
              {text}
            </div>
            <div
      style={{
        textAlign: 'center',
      }}>
      <i className="bi bi-geo-alt-fill" style={{top: '+1.3em'}}></i>
      </div>

    </div>
  );
  var xItem = 0;
 //console.log("dataArray", dataArray);

  return (
    <Card style={{ height: '900px' }}>
      <CardHeader>Fahrzeug</CardHeader>
      <CardBody height='250px'>
        <Row>
          <Col md={3}>
            <ListGroup>
              {dataArray.map((item, index) => {
                let xListItem = '';
                if ((item.gridStart > 1 && item.gridStart < 16) && xItem === 0) {
                  xItem = 1;
                  xListItem = (
                    <ListGroup.Item  key={'V' + index}>
                      <ListGroupItemHeading><strong>Vormittag</strong></ListGroupItemHeading>
                    </ListGroup.Item>
                  );
                } else if (item.gridStart > 17 && (xItem === 0 || xItem === 1)) {
                  xItem = 2;
                  xListItem = (
                    <ListGroup.Item  key={'N' + index}>
                      <ListGroupItemHeading><strong>Nachmittag</strong></ListGroupItemHeading>
                    </ListGroup.Item>
                  );
                } else {
                 //console.log("item.gridStart", item.gridStart);
                }
                return (
                  <>
                    {xListItem}
                    <ListGroup.Item
                      key={index}
                      onMouseOver={() => (
                        //setPosition({ lat: item.geo.lat, lng: item.geo.lng }),
                        setColor(prevColors => {
                          const newColors = [...prevColors];
                          newColors[index] = "rgba(255, 0, 230, .6)";
                          return newColors;
                        })
                      )}
                      onMouseOut={() => (
                        //setPosition({ lat: item.geo.lat, lng: item.geo.lng }),
                        setColor(prevColors => {
                          const newColors = [...prevColors];
                          newColors[index] = "rgba(158,226,230,.6)";
                          return newColors;
                        })
                      )}
                    >
                      <ListGroupItemHeading>
                        {item.start} - {item.end} Uhr
                      </ListGroupItemHeading>
                      <ListGroupItemText>
                        {item.name}<br />
                        {item.street}
                      </ListGroupItemText>
                    </ListGroup.Item>
                  </>
                );
              })}
            </ListGroup>
          </Col>
          <Col>
            <APIProvider apiKey={NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || ""}>
              <div style={{ height: "840px", width: "100%" }}>
                <Map
                  zoomControl={true}
                  scaleControl={true}
                  onCenterChanged={(e) => (
                    setPosition({ lat: e.detail.center.lat, lng: e.detail.center.lng })
                  )}
                  onZoomChanged={(e) => (
                    setZoom(e.detail.zoom)
                  )}
                  zoom={zoom} center={position} mapId={NEXT_PUBLIC_MAP_ID || ""}
                >
                  {
                    dataArray.map((item, index) => (
                      <AdvancedMarker
                        key={index}
                        position={{ lat: item.geo.lat, lng: item.geo.lng }}
                      >
                        <CustomMarker zeit={item.start + ' Uhr'} text={item.name} poscolor={poscolor[index]} />
                      </AdvancedMarker>
                    ))
                  }
                </Map>
              </div>
            </APIProvider>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}