import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home'
import TerminSearch from './pages/TerminSearch'
//import CalendarDayNew from './components/calendar/CalendarDayNew'
import './App.css';
import './scss/style.scss';


function App() {

  if(!localStorage.getItem('server') || localStorage.getItem('server') === 'https://d.24-expert.de') {
    localStorage.setItem('server', 'https://d.24-expert.de/service/v1.0');
    localStorage.setItem('imageserver', 'https://serviceimage.24-expert.de/');
    localStorage.setItem('dev', false);
  }

  if(window.innerWidth < 1280) {
    localStorage.setItem('clienttype', 'mobile');
  } else {
    localStorage.setItem('clienttype', 'desktop');
  }

 //console.log(process.env.REACT_APP_SERVER_URL);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact={true} path="/" element={<Home/>}/>
          <Route path="/day" element={<Home comp='CalendarDay' />}/>
          <Route path="/week" element={<Home comp='CalendarWeek' />}/>
          <Route path="/month" element={<Home comp='CalendarMonth' />}/>
          <Route path="/search" element={<Home comp='TerminSearch' />}/>
          <Route path="/list" element={<Home comp='DatesList' />}/>
          <Route path="/config" element={<Home comp='ServiceConfig' />}/>
          <Route path="/suchen" element={<TerminSearch/>}/>
        </Routes>
      </BrowserRouter>
      </>
  );
}

export default App;
