import React, { Component } from 'react';


export default class CalendarTimeGrid extends Component {
    constructor(props) {
        super(props);

        this.thisDay = true;
    }

    render() {
        //console.log("components/calendar/CalendarTimeGrid");

        var thisDay = false;

        var currentTime = new Date();

        const year = currentTime.toLocaleString("default", { year: "numeric" });
        const month = currentTime.toLocaleString("default", { month: "2-digit" });
        const day = currentTime.toLocaleString("default", { day: "2-digit" });
        const currentDate = year + '-' + month + '-' + day;


        if(this.props.CalendarDate === currentDate) {
            thisDay = true;
        }

        const std = currentTime.getHours();
        const min = currentTime.getMinutes();
    
        var xMin = std * 10;
    
    
        if(min > 29) {
            xMin = xMin + 5;
        }

        const tStart = [{
                "row": "calendar",
                "rowEnd": "h0800",
                "typ": "time text-center plan-top",
                "time": "nowtime",
                "t": 500
            }, {
                "row": 5,
                "typ": "time",
                "time": "08:00",
                "t": 80
            }, {
                "row": 6,
                "typ": "time",
                "time": "08:30",
                "t": 85
            }, {
                "row": 7,
                "typ": "time",
                "time": "09:00",
                "t": 90
            }, {
                "row": 8,
                "typ": "time",
                "time": "09:30",
                "t": 95
            }, {
                "row": 9,
                "typ": "time",
                "time": "10:00",
                "t": 100
            }, {
                "row": 10,
                "typ": "time",
                "time": "10:30",
                "t": 105
            }, {
                "row": 11,
                "typ": "time",
                "time": "11:00",
                "t": 110
            }, {
                "row": 12,
                "typ": "time",
                "time": "11:30",
                "t": 115
            }, {
                "row": 13,
                "typ": "time",
                "time": "12:00",
                "t": 120
            }, {
                "row": 14,
                "typ": "time",
                "time": "12:30",
                "t": 125
            },{
                "row": 15,
                "rowEnd": "h1300",
                "typ": "time text-center plan-top",
                "time": "",
                "t": 900
            }, {
                "row": 18,
                "typ": "time",
                "time": "13:00",
                "t": 130
            }, {
                "row": 19,
                "typ": "time",
                "time": "13:30",
                "t": 135
            }, {
                "row": 20,
                "typ": "time",
                "time": "14:00",
                "t": 140
            }, {
                "row": 21,
                "typ": "time",
                "time": "14:30",
                "t": 145
            }, {
                "row": 22,
                "typ": "time",
                "time": "15:00",
                "t": 150
            }, {
                "row": 23,
                "typ": "time",
                "time": "15:30",
                "t": 155
            }, {
                "row": 24,
                "typ": "time",
                "time": "16:00",
                "t": 160
            }, {
                "row": 25,
                "typ": "time",
                "time": "16:30",
                "t": 165
            }, {
                "row": 26,
                "typ": "time",
                "time": "17:00",
                "t": 170
            }, {
                "row": 27,
                "typ": "time",
                "time": "17:30",
                "t": 175
            }, {
                "row": 28,
                "typ": "time",
                "time": "18:00",
                "t": 180
            }, {
                "row": 29,
                "typ": "time",
                "time": "18:30",
                "t": 180
            }, {
                "row": 30,
                "typ": "time",
                "time": "19:00",
                "t": 180
            }
        ];

            return (
                tStart.map((element, index) => {
                    if(element.typ === "time") {
                        var typ = element.typ;
                        if(element.t < xMin && thisDay) {
                            typ += ' time-past';
                        } else if(element.t === xMin) {
                            typ += ' time-present';
                        }
                        return(
                            <div 
                                key={index} 
                                id={'t' + index} 
                                data-key={index} 
                                className={"text-center " + typ} 
                                style={{ gridRow: 'h' + element.time.replace(":", ""), gridColumn: 1 }}
                            >{element.time}</div>
                        );
                    } else if(element.typ === "time text-center plan-top") {
                        if(element.time === "nowtime" && thisDay) {
                            return(
                                <div 
                                    key={index} 
                                    id={'t' + index} 
                                    data-key={index} 
                                    className={'time text-center plan-top'} 
                                    style={{ 
                                        gridRow: element.row, 
                                        gridRowEnd: element.rowEnd, 
                                        gridColumn: 1 
                                    }}
                                >
                                    {this.props.nowtime}
                                </div>
                            );
                        } else if(element.time === "nowtime") {
                            return(
                                <div 
                                    key={index} 
                                    id={'t' + index} 
                                    data-key={index} 
                                    className={'time text-center plan-top'} 
                                    style={{ 
                                        gridRow: element.row, 
                                        gridRowEnd: element.rowEnd, 
                                        gridColumn: 1 
                                    }}
                                >
                                    {this.props.nowtime}
                                </div>
                            );
                        } else {
                            return(
                                <div key={index} id={'t' + index} data-key={index} className={element.typ} style={{ gridRow: element.row, gridRowEnd: element.rowEnd, gridColumn: 1 }}></div>
                            );
                        }
                    } else {
                        return (
                            <div></div>
                        );
                    }
                })
            );

    }
}
