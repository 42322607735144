import React, { Component } from 'react';
//import { Button, Card, CardHeader, CardFooter, Form, FormGroup, Input, Label, Table } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardHeader from 'react-bootstrap/CardHeader';
import CardFooter from 'react-bootstrap/CardFooter';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Table from 'react-bootstrap/Table';
import { ApiCall } from '../../functions/apicall';
import ExPagination from '../Pagination';
import ModalDate from '../../modals/ModalDate';

export default class DatesList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            time: Date.now(),
            isLoading: false,
            response: {rows: {rows: 0, pages: []}, rowdata: []},
            formData: {
                page: 1,
                limit: 10
            }

        }

        this.handleChange = this.handleChange.bind(this);
        this.search = this.search.bind(this);
        this.paging = this.paging.bind(this);
        this.onpaging = this.onpaging.bind(this);
        this.onClickExit = this.onClickExit.bind(this);
    }

    _fetchData = async () => {

        const setDataAdress = localStorage.getItem('server') + '/dates/list';

        const formData = this.state.formData;

        const searchParams = new URLSearchParams();

        searchParams.append("token", this.token);

        for (const key in formData) {
            if (formData.hasOwnProperty(key)) {
                searchParams.append("params[" + key + "]", formData[key]);
            }
        }
          
        const queryString = searchParams.toString();

        //console.log(queryString)

        ApiCall(setDataAdress, queryString)
        .then((response) => {
            //console.log(response);
            if(response.status) {
                this.setState({ 
                    time: Date.now(), 
                    isLoading: false,
                    response: response.data,
                    errorResponse: false 
                });
            } else {
                this.onClickExit();

            }

        })
        .catch((error) => {
            // Behandeln Sie Fehler, die von der Funktion ausgelöst werden
            this.errorResponse = true;

            this.setState({ 
                time: Date.now(), 
                isLoading: false,
                errorResponse: this.errorResponse 
            });
            //console.log('call', error);
        });
        
    }

    onClickExit() {
        sessionStorage.clear();
        window.location.href = '/';
    }

    search() {
        this.setState({ 
            time: Date.now(), 
            isLoading: true,
        });
    }

    paging(id) {
        this.setState({
            time: Date.now(), 
            isLoading: true,
            formData:{
                ...this.state.formData,
                page: id
            }
        });
    }

    onpaging(e) {
        console.log(e.target);
        //const { value } = e.target;
        //console.log(value);
        const d = e.target.dataset.page;
        //console.log(d);
        if(d) {
            this.paging(d);
        }
        
    }

    componentDidUpdate() {
        if(this.state.isLoading) {
            this._fetchData();
        }
    }

    componentDidMount() {
        //console.log("SparepartList");
        if(sessionStorage.getItem('myService')) {
            const response = sessionStorage.getItem('myService');
            const responseJson = JSON.parse(response);
            this.token = responseJson.Ergebnis;
            this.setState({
                token: responseJson.Ergebnis,
            });
        }        
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({
            formData:{
                ...this.state.formData,
                [name]: value
            }
        });
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    Termin Liste
                </CardHeader>
                <Form>

                <Table
                    hover
                    
                >
                    <thead style={{position: 'sticky', top: '0', zIndex: 1, backgroundColor: 'white'}}>
                        <tr>
                            <th style={{width: '5%'}}>

                            <Button
                                onClick={this.search}
                                color="primary"
                                size="lg"
                                style={{width: '100%'}}
                            >
                                <i className="bi bi-search"></i>
                            </Button>
                            </th>
                            <td style={{width: '15%'}}>
                                <FloatingLabel 
                                    label="Kunde"
                                >
                                    <Form.Control 
                                        onChange={this.handleChange}
                                        id="name"
                                        name="name"
                                        placeholder="Kunde"
                                        value={this.state.formData.name || ''}
                                        type="text"
                                    />
                                </FloatingLabel>
                            </td>
                            <td style={{width: '20%'}}>
                                <FloatingLabel 
                                    label="Adresse"
                                >
                                    <Form.Control 
                                        onChange={this.handleChange}
                                        id="address"
                                        name="address"
                                        placeholder="Adresse"
                                        value={this.state.formData.address || ''}
                                        type="text"

                                    />
                               
                                </FloatingLabel>

                            </td>
                            <td style={{width: '40%'}}>
                                <FloatingLabel label="Auftrag">
                                    <Form.Control 
                                        onChange={this.handleChange}
                                        id="auftrag"
                                        name="auftrag"
                                        placeholder="Auftrag"
                                        value={this.state.formData.auftrag || ''}
                                        type="text"

                                    />                                    
                                </FloatingLabel>
                            </td>
                            <td style={{width: '5%'}}>
                                <FloatingLabel label="Datum">
                                    <Form.Control 
                                        onChange={this.handleChange}
                                        id="datum"
                                        name="datum"
                                        placeholder="Datum"
                                        type="date"
                                        value={this.state.formData.datum || ''}
                                    />                                      
                                </FloatingLabel>
                            </td>
                            <td style={{width: '5%'}}>
                                <FloatingLabel label="Status">
                                    <Form.Select
                                        onChange={this.handleChange}
                                        id="status"
                                        name="status"
                                        type="select"
                                        placeholder="Datum"
                                        value={this.state.formData.status || ''}

                                    >
                                        <option value=""></option>
                                        <option value={0}>00 - angelegt</option>
                                        <option value={1}>01 - E-Teil Folgetermin</option>
                                        <option value={10}>10 - geplant</option>
                                        <option value={20}>20 - Folgetermin geplant</option>
                                        <option value={40}>40 - Kunde benachrichtigt</option>
                                        <option value={50}>50 - aktuell vor Ort</option>
                                        <option value={51}>51 - aktuell vor Ort</option>
                                        <option value={55}>55 - aktuell vor Ort - PAUSE</option>
                                        <option value={60}>60 - E-Teil bestellt</option>
                                        <option value={65}>65 - E-Teil Folgetermin</option>
                                        <option value={70}>70 - nicht erfolgreich</option>
                                        <option value={80}>80 - erfolgreich abgeschlossen</option>
                                        <option value={90}>90 - berechnet</option>
                                        <option value={99}>99 - Storniert</option>
                                    </Form.Select>                                     
                                </FloatingLabel>
                            </td>
                        </tr>      
                    </thead>
                    <tbody>
                        {
                            this.state.response.rowdata.map((element, index) => {
                                return (
                                    <ModalDate modtyp="listWorkSearch" key={index} element={element} />
                                )
                            })
                        }
                    </tbody>
                </Table>
                </Form>
                <CardFooter>
                    <div className="row">
                        <div className="col text-start">
                            {this.state.response.rows.rows} Ergebnisse
                        </div>
                        <div className="col">
                            <ExPagination pages={this.state.response.rows} onPaging={this.onpaging}/>
                        </div>
                        <div className="col text-end">
                            {this.state.response.rows.pagescount} Seiten
                        </div>
                    </div>
                </CardFooter>
            </Card>
        );
    }


}

