import React, { useRef, useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';


export default function ExSignature(props) {

    const sigRef = useRef();
    const [signature, setSignature] = useState(null);
    const handleSignatureEnd = () => {
      setSignature(sigRef.current.toDataURL());
    }
    /*const clearSignature = () => {
      sigRef.current.clear();
      setSignature(null);
    }*/
  
    useEffect(() => {
      let ng = new Date().toLocaleString('de-DE');
      const canvas = sigRef.current.getCanvas();
      const ctx = canvas.getContext('2d');
      ctx.font = '10px Arial';
      ctx.fillStyle = '#a1a1a1';
      ctx.textAlign = 'center';
      ctx.fillText(ng, 180, 145);      
      ctx.font = '40px Arial';
      ctx.fillStyle = '#aaa';
      ctx.textAlign = 'center';
      ctx.fillText('________________________________________________', 180, 125);      
      //console.log(signature);
      props.onSign(signature);
    }, [props, signature]);

 
    return (
            <div>
                <SignatureCanvas 
                    className="position-relative bottom-0 end-0 border-end"
                    penColor="black"
                    backgroundColor="white"
                    canvasProps={{className: 'signature'}}
                    ref={sigRef}
                    onEnd={handleSignatureEnd}
                    
                />
            </div>

    );

}
