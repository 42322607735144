import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import CalendarTimeGrid from './CalendarTimeGrid';
import CalendarItems from './CalendarItems';
import TableHeadDay from '../TableHeadDay';
import NavTabCalendar from './components/NavTabCalendar';
import ModalSpinner from '../ModalSpinner';
import CalendarTimeIndicator from './components/CalendarTimeIndicator';

export default class CalendarDay extends Component {

    state = {
        isLoading: true,
        time: Date.now(),
        data: [],
        dates: { modal: "false", data: [] },
        fetchDate: null,
        myService: null,
        myUser: null,
        myHash: '',
        pCode: '',
        token: null
    };

    constructor(props) {
        super(props);
    
        this.nocode = '';
        this.fetchDate = null;
        this.timeOut = null
        this.type = null;
        this.gridCol = '';


        this.onChangesDay = this.onChangesDay.bind(this);

        this.onSetCity = this.onSetCity.bind(this);
        this.onSetPerson = this.onSetPerson.bind(this);

        this.onMoveDate = this.onMoveDate.bind(this);
    
        this.onClickFree = this.onClickFree.bind(this);
        this.onSetComp = this.onSetComp.bind(this);

        this.handleOnUpdate = this.handleOnUpdate.bind(this);

        this.onClickFree = this.onClickFree.bind(this);
        this.onReload = this.onReload.bind(this);
        this.removeTimer = this.removeTimer.bind(this);
        this.onDatesInsert = this.onDatesInsert.bind(this);
    }

    _fetchData = async () => {
        clearTimeout(this.timeOut);
        let responseJson;

        var body = "token=" + this.token;

        if(sessionStorage.getItem('myDate')) {
            body += `&params[date]=` + sessionStorage.getItem('myDate')
        }

        if(localStorage.getItem('clienttype') === 'mobile') {
            body += `&params[calendars]=` + localStorage.getItem('calendarid');
        }
        
        body += "&params[status]=1";
        body += "&params[storno]=no";
        body += "&params[hash]=" + this.state.myHash;
        

        //console.log('TerminPlaner_fetchData', Date(), body)

        try {
            const response = await fetch(localStorage.getItem('server') + '/calendar/day/', {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },            
                method: 'POST',
                body: body
            });
           //console.log('response', response.clone().text());
            responseJson = await response.json();
           //console.log(new Date().toString(), 'responseJson', responseJson);

        } catch (error) {
            // Handle the timeout error here
          //console.log(new Date().toString(), 'Timeout error:', error);
        }

        if(responseJson && responseJson.data !== 'nochange') {
            this.setState({ 
                isLoading: false,
                data: responseJson, 
                time: Date.now(), 
                myHash: responseJson.parameters.dataHash
            });
        }
        this.timeOut = setTimeout(() => {
            this.updateTime();
        }, 10000);
        
    };

    _setData = async () => {
        //console.log(this.setDataAdress, this.setDataBody)

        // eslint-disable-next-line no-unused-vars
        const response = await fetch(this.setDataAdress, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },            
            method: 'POST',
            body: this.setDataBody,
        });

        //console.log("_setData",responseJson);

        this._fetchData();

    };


    onSetPerson(value, dataset) {

        this.setDataAdress = localStorage.getItem('server') + '/preset/person';

        this.setDataBody = "action=setPresetPerson";
        this.setDataBody += "&params[cal]=" + dataset.cal;
        this.setDataBody += "&params[field]=" + dataset.field;
        this.setDataBody += "&params[user]=" + value;
        this.setDataBody += "&params[day]=" + dataset.day;
        this.setDataBody += "&params[daytime]=" + dataset.daytime;
        this.setDataBody += "&token=" + this.token;



        //console.log('setDataBody', this.setDataBody);

        this._setData();
    }

    onSetCity(value, dataset) {

        this.setDataAdress = localStorage.getItem('server') + '/preset/city';

        this.setDataBody = "action=setPresetCity";
        this.setDataBody += "&params[cal]=" + dataset.cal;
        this.setDataBody += "&params[city]=" + value;
        this.setDataBody += "&params[day]=" + dataset.day;
        this.setDataBody += "&params[daytime]=" + dataset.daytime;
        this.setDataBody += "&token=" + this.token;


        //console.log('setDataBody', this.setDataBody);

        this._setData();
    }

    onMoveDate(e) {

        //console.log('onMoveDate',e.dataTransfer.getData("id"), e.target.dataset.calendar, e.target.dataset);

        this.setDataAdress = localStorage.getItem('server') + '/dates/move';

        this.setDataBody = "token=" + this.token;
        this.setDataBody += "&params[id]=" + e.dataTransfer.getData("id");
        this.setDataBody += "&params[orderid]=" + e.dataTransfer.getData("orderid");
        this.setDataBody += "&params[calendar]=" + e.dataTransfer.getData("calendar");
        this.setDataBody += "&params[date]=" + e.dataTransfer.getData("date");
        this.setDataBody += "&params[start]=" + e.dataTransfer.getData("start");
        this.setDataBody += "&params[daytime]=" + e.dataTransfer.getData("daytime");
        this.setDataBody += "&params[runtime]=" + e.dataTransfer.getData("runtime");
        this.setDataBody += "&params[targetCalendar]=" + e.target.dataset.calendar;
        this.setDataBody += "&params[targetDate]=" + e.target.dataset.date;
        this.setDataBody += "&params[targetStart]=" + e.target.dataset.start;
        this.setDataBody += "&params[targetDaytime]=" + e.target.dataset.daytime;

       //console.log('onMoveDate', this.setDataBody);
        
        this._setData();
    }

    onChangesDay(event) {
        //console.log('onChangesDay', event.target);
        if(event.target.dataset.date) {
            sessionStorage.setItem('myDate', event.target.dataset.date);
            this.setState({ isLoading: true,fetchDate: event.target.dataset.date});
        } else {
            sessionStorage.setItem('myDate', event.target.value);
            this.setState({ isLoading: true,fetchDate: event.target.value});
        }
    }

    onSetComp(dataset) {
        //console.log('onSetComp', dataset);
        this.props.onSetComp(dataset);
    }

    updateTime() {
        //console.log('updateTime');
        this.setState({time: Date.now()});
    }

    componentDidMount() {
        if(sessionStorage.getItem('myService')) {
            const response = sessionStorage.getItem('myService');
            const responseJson = JSON.parse(response);
            this.token = responseJson.Ergebnis;
            this.type = this.props.type;
            this.setState({
                myService: responseJson,
                token: responseJson.Ergebnis,
                fetchDate: this.props.fetchDate
            });
        }
    }

    handleOnUpdate() {
        this._fetchData();
    }

    componentDidUpdate() {
        this._fetchData();
    }

    componentWillUnmount() {
       clearTimeout(this.timeOut);
    }    

    onClickFree(event) {
        //console.log(event.target.dataset);
    }

    onReload() {
        this._fetchData();
    }

    onDatesInsert() {
        this._fetchData();
    }

    removeTimer() {
        //console.log('CalendarDay updateTime clear');
        clearTimeout(this.timeOut);

    }

    render() {
        //console.log("components/calendar/Calendar", "ExCalendar");
        //console.log('this.props', this.props);



        if(this.state.data.data) {
            //this.state.isLoading === false

            const datacalendar = this.state.data.data;
            const action = this.state.data.action;

            //console.log('datacalendar', datacalendar);
            
            var currentTime = new Date();
    
            var nowtime = currentTime.getHours().toString().padStart(2, '0') + ":" + currentTime.getMinutes().toString().padStart(2, '0');

            const rAbteilung = ['6','8','11'];

            const myservice = JSON.parse(sessionStorage.getItem('myService'));

            var grid = '90px';
            var timeGrid = <CalendarTimeGrid nowtime={nowtime} CalendarDate={datacalendar.datum} />;

            this.gridCol = '';
            datacalendar.calendar.forEach(element => {
                this.gridCol += '[ct' + element.id + '] 5px [cd' + element.id + '] 1fr ';
                //this.gridCol += "[cd" + element.id + "] 1fr";
                
            }); 

            if(!rAbteilung.includes(myservice['MitarbeiterAbteilung'])) {
                grid = '0fr';
                timeGrid = null;
            }

            return (
                <Card>
                    <ModalSpinner isloading={this.state.isLoading} />
                    <NavTabCalendar onChangesDay={this.onChangesDay} calDate={datacalendar.datum} period={action.thisWeek} />
                    <TableHeadDay 
                        type='day' 
                        action={action} 
                        onChangesDay={this.onChangesDay} 
                        period={datacalendar.period} 
                        badge={'KW' + action.thisKW.kw} 
                        lable={datacalendar.period[0].dayName + ', ' + datacalendar.header}
                    />
                    <Card.Body className="p-0">
                        
                        <div    
                            className="exp-calendar-grid"
                            id="exp-calendar-grid" 
                            style={{ 
                                //gridTemplateColumns: grid + ' repeat(' + datacalendar.calendar.length + ', 5px 2fr)' 

                                gridTemplateColumns: grid + ' ' + this.gridCol 
                            }}>
                            {timeGrid}

                            {
                                // eslint-disable-next-line array-callback-return
                                datacalendar.calendar.map((element, index) => {

                                    var itemData;

                                    if(datacalendar.calendar[index].data && datacalendar.calendar[index].data[datacalendar.datum]) {
                                        itemData = datacalendar.calendar[index].data[datacalendar.datum];
                                    } else {
                                        itemData = [];
                                    }

                                    return <CalendarTimeIndicator 
                                                key={index}
                                                type='day'
                                                itemData = {itemData}
                                                element = {element}
                                                CalendarDate={datacalendar.datum} 
                                            />
                                })
                            }




                            {
                                // eslint-disable-next-line array-callback-return
                                datacalendar.calendar.map((element, index) => {
                                    //console.log(element);
                                    //if (element.status === 'true') {

                                        var itemData;

                                        if(datacalendar.calendar[index].data && datacalendar.calendar[index].data[datacalendar.datum]) {
                                            itemData = datacalendar.calendar[index].data[datacalendar.datum];
                                        } else {
                                            itemData = [];
                                        }
                                        //console.log("CalendarItem", itemData);
                                        return (
                                            <CalendarItems 
                                                key={index} 
                                                type='day'
                                                changeType='CalendarWeek'
                                                Lable={element['lable']}
                                                myservice={this.props.myservice} 
                                                ColumnStart={index + 2} 
                                                CalendarDate={datacalendar.datum} 
                                                CalendarItem={element}
                                                itemData={itemData}
                                                itemDates={datacalendar.calendar[index].dates[datacalendar.datum]}
                                                itemDouble={datacalendar.calendar[index].dates['n'][datacalendar.datum]}
                                                calendarId={element['id']} 
                                                person={datacalendar.person[datacalendar.datum]} 
                                                personList={datacalendar.personList[datacalendar.datum]} 
                                                personUsed={datacalendar.personUsed[datacalendar.datum]} 
                                                onSetPerson={this.onSetPerson} 
                                                onSetCity={this.onSetCity}
                                                onSetComp={this.onSetComp}
                                                onMoveDate={this.onMoveDate}
                                                onUpdate={this.handleOnUpdate}
                                                removeTimer={this.removeTimer}
                                                onDatesInsert={this.onDatesInsert}
                                            />
                                        );
                                    //}
                                })
                            }

                        </div>

                    </Card.Body>
                </Card>
            );
        } else {
            return (
                <ModalSpinner isloading={this.state.isLoading} />
            );
        }
    }
}



/*
<div
  style={{
     opacity: spinning > 0 ? 0.25 : 1,
     pointerEvents: spinning > 0 ? 'none' : 'auto'
  }}>

   <...usual components />
</div>




                        {locked}


                //console.log('calendar:', index, element)

                var xHours = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25];

                const x = element.data;
                const y = this.props.person;
                const ikey = element.id * 1000;

                //var locked = [];
                //var name = '';
                //var calendarDate = '';
                //var dt = 1;
                //var xkey = '';
                
                xHours.map((i) => {
                    

                    xkey = "cal" + (ikey + i).toString()
                    //if(i === 0) {
                        //console.log(i);
                        return (<div key={index} className="plan-top" style={{gridRowStart: 1, gridColumnStart: index + 2 }}>{element['lable']}</div>);
                    //}
                    /* else if(i === 1) {
                        return (<div key={xkey} data-key={xkey} className="plan-top-info" style={{gridRowStart: 2, gridColumnStart: index + 2 }}>{element['name']}</div>);
                    } else if(i === 2) {
                        if(x && x[this.props.calendardate][1]['daytime']) {
                            return (<div key={xkey} data-key={xkey} className="text-center plan-top-info" style={{gridRowStart: i, gridColumnStart: index + 2 }}>{x[this.props.calendardate][1]['city']}</div>);
                            //calendarDate = x[this.props.calendardate][1]['date'];
                        } else {
                            return (<div key={xkey} data-key={xkey} className="plan-top-info" style={{gridRowStart: i, gridColumnStart: index + 2 }}></div>);
                            //locked.push(<div key={"cal" + (ikey + i * 100).toString()} data-key={"cal" + (ikey + i * 100).toString()} className="plan plan-locked" style={{gridRowStart: i + 3, gridRowEnd: 13, gridColumnStart: index + 2 }}></div>);
                        }
                    } else if(i === 3) {
                        if(x && x[this.props.calendardate][1]['daytime']) {
                            if(y[this.props.calendardate][x[this.props.calendardate][1]['user_primary']]) {
                                name = y[this.props.calendardate][x[this.props.calendardate][1]['user_primary']]['shortname'];
                            } else {
                                name = "ACHTUNG!";
                            }
                            if(x[this.props.calendardate][1]['user_secondary'] !== '0') {
                                if( y[this.props.calendardate][x[this.props.calendardate][1]['user_secondary']] ) {
                                    name += ", " + y[this.props.calendardate][x[this.props.calendardate][1]['user_secondary']]['shortname']
                                } else {
                                    name += ", ACHTUNG!";
                                }
                            }
                            return (<div key={xkey} data-key={xkey} className="text-center plan-top-info" style={{gridRowStart: i, gridColumnStart: index + 2 }}>{name}</div>);
                        } else {
                            return (<div key={xkey} data-key={xkey} className="plan-top-info" style={{gridRowStart: i, gridColumnStart: index + 2 }}>nicht geplant</div>);
                        }
                    } else if(i === 14) {
                        if(x && x[this.props.calendardate][2]['daytime']) {
                            return (<div key={xkey} data-key={xkey} className="text-center plan-top-info" style={{gridRowStart: i, gridColumnStart: index + 2 }}>{x[this.props.calendardate][2]['city']}</div>);
                            //calendarDate = x[this.props.calendardate][2]['date'];
                        } else {
                            return (<div key={xkey} data-key={xkey} className="plan-top-info" style={{gridRowStart: i, gridColumnStart: index + 2 }}></div>);
                            //locked.push(<div key={"cal" + (ikey + i * 100).toString()} data-key={"cal" + (ikey + i * 100).toString()} className="plan plan-locked" style={{gridRowStart: i + 3, gridRowEnd: 25, gridColumnStart: index + 2 }}></div>);
                        }

                    } else if(i === 15) {
                        if(x && x[this.props.calendardate][2]['daytime']) {
                            if(y[this.props.calendardate][x[this.props.calendardate][2]['user_primary']]) {
                                name = y[this.props.calendardate][x[this.props.calendardate][2]['user_primary']]['shortname'];
                            }
                            if(x[this.props.calendardate][2]['user_secondary'] !== '0') (
                                name += ", " + y[this.props.calendardate][x[this.props.calendardate][2]['user_secondary']]['shortname']
                            )

                            return (<div key={xkey} data-key={xkey} className="text-center plan-top-info" style={{gridRowStart: i, gridColumnStart: index + 2 }}>{name}</div>);
                        } else {
                            return (<div key={xkey} data-key={xkey} className="plan-top-info" style={{gridRowStart: i, gridColumnStart: index + 2 }}>nicht geplant</div>);
                        }

                    } else if(i === 4 || i === 3 || i === 13 || i === 16 || i === 25 || i === 26 || i === 27 || i === 28 ) {
                        if(i < 14) {dt = 1} else {dt = 2}
                        return (<ExModalDateAdd xkey={xkey} data-key={xkey} date={calendarDate} daytime={dt} element={element} rowStart={i} colStart={index + 2} classElement="plan-none"  onDatesInsert={this.props.onDatesInsert}/>);
                    } else {
                        //console.log('else', i);
                        if(i < 14) {dt = 1} else {dt = 2}
                        //return (<ExModalDateAdd xkey={xkey} data-key={xkey} date={calendarDate} daytime={dt} element={element} rowStart={i} colStart={index + 2} classElement="plan-blanc"  onDatesInsert={this.props.onDatesInsert}/>);
                    }              
                })

*/
