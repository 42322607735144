import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class OptData extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            error: null,
            loading: false
        }

        this.getArea = this.getArea.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
       //console.log('OptData componentDidMount', this.props);
        this.setState({ 
            data: this.props.artikel.optData,
            loading: true 
        });
    }

    componentDidUpdate() { 
       //console.log('OptData componentDidUpdate', this.props.datapos, "state", this.state.data);
        if(this.state.data !== this.props.artikel.optData) {
            this.props.onChangeOptionData(this.props.datapos, this.state.data); 
        }
    }

    getArea(a) {
        let ret = false;
        if(a !== null) {
            a.forEach(element => {
                //console.log('element', element);
                if(this.props.artikel[element] === true) {
                    ret = true;
                }
            });
        }
        return ret;
    }

    handleChange(event) {
       //console.log('handleChange', event.target.type, event.target.name, event.target.value);
        let data = this.state.data;
        switch(event.target.type) {
            case 'text':
            case 'textarea':
            case 'radio':
                    data[event.target.name] = event.target.value;
                break;
            case 'checkbox':
               //console.log('checkbox', this.state.data[event.target.name])
                if(!data[event.target.name]) {
                    data[event.target.name] =[event.target.value];
                } else {
                    if(event.target.checked === true) {
                        data[event.target.name].push(event.target.value);
                    } else {
                        data[event.target.name].splice(data[event.target.name].indexOf(event.target.value), 1);
                    }                      
                } 
                break;
            default:
                break;
        }

        this.setState({
            data: data
        });
    }

    render() {
        
        const myProductInfo = JSON.parse(sessionStorage.getItem('myProductInfo'));
        const props = this.props;
        //console.log('OptData', props, this.props, this.state.data, myProductInfo);
    

        return (
            <Form.Group as={Row}>
                {


                    (props.artikel.deliver === true || props.artikel.setup === true || props.artikel.connect === true || props.artikel.dispose === true) 
                    
                    ?   // eslint-disable-next-line array-callback-return
                        myProductInfo[this.props.productarea].map((item, index) => {

                            switch(item.typ) {
                                case 'text':
                                    if(this.getArea(item.area) === true) {
                                        return (
                                            <Form.Group key={index} as={Row}>
                                                <Col className='col-3 text-end'>
                                                    {item.name}
                                                </Col>
                                                <Col>
                                                    <Form.Control 
                                                        key={index} 
                                                        type="text"
                                                        value={this.state.data[item.name]}
                                                        placeholder={item.placeholder} 
                                                        name={item.name}
                                                        onLostPointerCapture={this.handleChange}

                                                    />
                                                </Col>
                                            </Form.Group>
                                        )
                                    }
                                    break;
                                case 'textarea':
                                    if(this.getArea(item.area) === true) {

                                        return (
                                            <Form.Group key={index} as={Row}>
                                                <Col className='col-3 text-end'>
                                                    {item.name}
                                                </Col>
                                                <Col>
                                                    <Form.Control 
                                                        key={index} 
                                                        as="textarea" 
                                                        placeholder={item.placeholder}
                                                        name={item.name}
                                                        value={this.state.data[item.name]}
                                                        onChange={this.handleChange}
                                                        
                                                    />
                                                </Col>
                                            </Form.Group>
                                        )
                                    }
                                    break;                                    
                                case 'select':
                                    if(this.getArea(item.area) === true) {

                                        return (
                                            <Form.Control
                                                key={index}
                                                type="select"
                                                placeholder={item.name}
                                            />

                                        )
                                    }
                                    break;
                                case 'checkbox':
                                    if(this.getArea(item.area) === true){
                                        return (
                                            <Form.Group key={index} as={Row}>
                                                <Col className='col-3 text-end'>
                                                    {item.name}
                                                </Col>
                                                <Col>
                                                {
                                                    item.option.map((option, oindex) => {
                                                        return (
                                                            <Form.Check 
                                                                className='position-relative top-0 mb-2'
                                                                inline
                                                                key={oindex}
                                                                type="checkbox"
                                                                label={option.name}
                                                                name={item.name}
                                                                value={option.name}
                                                                checked={this.state.data[item.name]?.includes(option.name)}
                                                                onChange={this.handleChange}

                                                            />
                                                        )
                                                    })
                                                }
                                                </Col>
                                            </Form.Group>
                                        )                                        
                                    }
                                    break;
                                case 'radio':
                                    if(this.getArea(item.area) === true) {
                                    
                                        if(item.option !== null) {
                                            return (
                                                <Form.Group key={index} as={Row}>
                                                    <Col className='col-3 text-end'>
                                                        {item.name}
                                                    </Col>
                                                    <Col>
                                                        {
                                                            item.option.map((option, oindex) => {
                                                                return (
                                                                    <Form.Check 
                                                                        className='position-relative top-0 mb-2'
                                                                        inline
                                                                        key={oindex}
                                                                        type="radio"
                                                                        label={option.name}
                                                                        name={item.name}
                                                                        value={option.name}
                                                                        checked={this.state.data[item.name] === option.name}
                                                                        onChange={this.handleChange}
                                                                        />
                                                                )
                                                            })
                                                        }
                                                    </Col>
                                                </Form.Group>
                                            )
                                        }
                                    }
                                    break;
                                case 'info':
                                    if(this.getArea(item.area) === true) {
                                        return (
                                            <Form.Group as={Row}>
                                                <Col className='col-3 text-end'>
                                                </Col>
                                                <Col>
                                                    <Form.Text className='form-control-lg text-end mb-0 pb-0 pt-0'>
                                                        {item.defaultvalue}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>         
                                        )                               
                                    }
                                    break;

                                default:

                                    break;

                                }

                        }) 

                    :   <Form.Group as={Row}>
                            <Col className='col-3 text-end'>
                            </Col>
                            <Col>
                                <Form.Text className='form-control-lg text-end mb-0 pb-0 pt-0'>
                                    {"Bitte vereinbarte Aufgaben anklicken >>>"}
                                </Form.Text>
                            </Col>
                        </Form.Group>     
                                    
                }

            </Form.Group>
        )  
    }
}