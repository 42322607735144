import React, { Component } from 'react';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import ExSignature from './Signature'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Alert from 'react-bootstrap/Alert';


export default class TerminWorkorder extends Component {

    state = {
        isloading: false,
        element: null,
        calendardate: null,
        sign: null,
        newArtikel: '',
        newText:'',
        formData: {
            artikel: [],
        },
        setDataAdress: null,
        setDataBody: null,
        tab: 'text',
        closed: false
    };

    constructor(props) {
        super(props);



        this.sign = '';
        this.setDataBody = [];
        this.setDataAdress = '';

        this.onSign = this.onSign.bind(this);
        this.onSignOk = this.onSignOk.bind(this);
        this.addArtikel = this.addArtikel.bind(this);
        this.addText = this.addText.bind(this);
        this.submitText = this.submitText.bind(this);
        this.updatePosition = this.updatePosition.bind(this);
        this.deletePosition = this.deletePosition.bind(this);   
    }

    

    componentDidMount() {
        //console.log('componentDidMount');

        if(sessionStorage.getItem('myService')) {
            const response = sessionStorage.getItem('myService');
            const responseJson = JSON.parse(response);
            this.token = responseJson.Ergebnis;
            this.type = this.props.type;
            const closed = this.props.element.sign === null ? false : true;
            this.setState({
                isloading: true,
                myService: responseJson,
                element: this.props.element,
                calendardate: this.props.calendardate,
                token: responseJson.Ergebnis,
                closed: closed
            });
        }
    }

    componentDidUpdate() {
        if(this.state.setDataAdress !== null) {
            this._setData();
        }
    }

    _setData = async () => {
        //console.log(this.setDataAdress, this.setDataBody)

        const response = await fetch(this.setDataAdress, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },            
            method: 'POST',
            body: this.setDataBody,
        });
        //console.log(response.text());
        // eslint-disable-next-line no-unused-vars
        const responseJson = await response.json();

        //console.log("_setData",responseJson);
        if(this.state.setDataAdress === localStorage.getItem('server') + '/dates/sign') {
            window.location.reload(false);
        }
        this.setState({
            setDataAdress: null,
            setDataBody: null,
        });
    }

    _fetchArtikel = async (body) => {
       //console.log(body);
     
             const response = await fetch(localStorage.getItem('server') + '/artikel/get', {
                 headers: {
                     'Content-Type': 'application/x-www-form-urlencoded',
                 },            
                 method: 'POST',
                 body: body,
             });
             // eslint-disable-next-line no-unused-vars
             //console.log(response.text());
             const responseJson = await response.json();
             let artikel = this.state.formData.artikel;
             let workOrder = this.state.element.workOrder;
             const artnr = responseJson.parameters.params.id;
             const tln = workOrder.length + 1;
 
           //console.log("return", artnr, responseJson.status.data, responseJson);
 
             if(responseJson.status !== false && responseJson.data !== false) {
 
                 //Artikel  bezeichnung und menge zu this.state.artikel hinzufügen
 
                 artikel.push({artnr: artnr, arttyp: 'bekannt', name: responseJson.data.bezeichnung, menge: 1, deliver: false, setup: false, connect: false, dispose: false, optData: {}});
                 workOrder.push({typ: 'ARTIKEL', artnr: artnr, bezeichnung: responseJson.data.bezeichnung, menge: 1, einheit: 'STK', ln: tln, pid: this.state.myService.Mitarbeiter});

                 this.setDataBody = "token=" + this.state.token;
                 this.setDataBody += "&params[order_id]=" + this.state.element.order_id;
                 this.setDataBody += "&params[order_dates_id]=" + this.state.element.id;
                 this.setDataBody += "&params[ln]=" + tln;
                 this.setDataBody += "&params[typ]=ARTIKEL";
                 this.setDataBody += "&params[artnr]=" + artnr;
                 this.setDataBody += "&params[bezeichnung]=" + responseJson.data.bezeichnung;
                 this.setDataBody += "&params[menge]=1";
                 this.setDataBody += "&params[einheit]=STK";
                 this.setDataBody += "&params[netto]=0";
                 this.setDataBody += "&params[mwst]=19";
                 this.setDataBody += "&params[pid]=" + this.state.myService.Mitarbeiter;

                 this.setDataAdress = localStorage.getItem('server') + '/dates/addPosition';

                 //console.log("return", responseJson);
                 //return responseJson.data;
                 //this.props.toggle();
                 //window.location.reload(false);
     
             } else if(responseJson.status) {
                 artikel.push({artnr: artnr, arttyp: 'unbekannt', name: '', menge: 1, deliver: false, setup: false, connect: false, dispose: false, optData: {}});

             }        
             this.setState({
                formData: {
                     ...this.state.formData,
                     artikel: artikel
                },
                element: {
                    ...this.state.element,
                    workOrder: workOrder
                },
                newArtikel: '',
                setDataAdress: this.setDataAdress,
                setDataBody: this.setData
             });                
    }

    addArtikel(event) {
        if(event.target.value.length === 11) {
            //console.log(event.target.value);
            var body = '';
            body += `params[id]=` + event.target.value
            body += "&token=999988887777666655554444333322221111";
            //console.log(body);
            this._fetchArtikel(body);
        } else {
            this.setState({
                newArtikel: event.target.value
            });
        }
    }

    deletePosition(event) {
       //console.log(event.target.getAttribute('line'));
        let workOrder = this.state.element.workOrder;
        const line = event.target.getAttribute('line');
        const menge = event.target.getAttribute('menge');

        const tln = workOrder[line].id;

        this.setDataBody = "token=" + this.state.token;
        this.setDataBody += "&params[order_id]=" + this.state.element.order_id;
        this.setDataBody += "&params[order_dates_id]=" + this.state.element.id;
        this.setDataBody += "&params[id]=" + tln;
        this.setDataBody += "&params[menge]=" + menge;
        this.setDataBody += "&params[typ]=DELETE";
        this.setDataBody += "&params[pid]=" + this.state.myService.Mitarbeiter;

        this.setDataAdress = localStorage.getItem('server') + '/dates/updatePosition';

        workOrder.splice(line, 1);
        this.setState({
            element: {
                ...this.state.element,
                workOrder: workOrder
            },
            setDataAdress: this.setDataAdress,
            setDataBody: this.setDataBody,

        });
    }    

    updatePosition(event) {
       //console.log(event.target.getAttribute('line'));
        let workOrder = this.state.element.workOrder;
        const line = event.target.getAttribute('line');
        const menge = event.target.getAttribute('menge');

        const tln = workOrder[line].id;
        const typ = workOrder[line].typ;

        this.setDataBody = "token=" + this.state.token;
        this.setDataBody += "&params[order_id]=" + this.state.element.order_id;
        this.setDataBody += "&params[order_dates_id]=" + this.state.element.id;
        this.setDataBody += "&params[id]=" + tln;
        this.setDataBody += "&params[menge]=" + menge;
        this.setDataBody += "&params[typ]=" + typ;
        this.setDataBody += "&params[pid]=" + this.state.myService.Mitarbeiter;

        this.setDataAdress = localStorage.getItem('server') + '/dates/updatePosition';

        workOrder[line].menge = menge;
        this.setState({
            element: {
                ...this.state.element,
                workOrder: workOrder
            },
            setDataAdress: this.setDataAdress,
            setDataBody: this.setDataBody,

        });
    }

    addText(event) {    
        this.setState({
            newText: event.target.value
        });
    }

    submitText() {

        let workOrder = this.state.element.workOrder;
        const tln = workOrder.length + 1;

        workOrder.push({typ: 'TEXT', artnr: 'TEXT', bezeichnung: this.state.newText, menge: 0, einheit: '', ln: tln, pid: this.state.myService.Mitarbeiter});


        this.setDataBody = "token=" + this.state.token;
        this.setDataBody += "&params[order_id]=" + this.state.element.order_id;
        this.setDataBody += "&params[order_dates_id]=" + this.state.element.id;
        this.setDataBody += "&params[ln]=" + tln;
        this.setDataBody += "&params[typ]=TEXT";
        this.setDataBody += "&params[artnr]=null";
        this.setDataBody += "&params[bezeichnung]=" + this.state.newText;
        this.setDataBody += "&params[menge]=0";
        this.setDataBody += "&params[einheit]=";
        this.setDataBody += "&params[netto]=";
        this.setDataBody += "&params[mwst]=";
        this.setDataBody += "&params[pid]=" + this.state.myService.Mitarbeiter;

        this.setDataAdress = localStorage.getItem('server') + '/dates/addPosition';

        this.setState({
            element: {
                ...this.state.element,
                workOrder: workOrder
            },
            newArtikel: '',
            newText: '',
            setDataAdress: this.setDataAdress,
            setDataBody: this.setData
        });

    }

    onSign(sign) {
        //console.log(sign);
        //this.setState({
        this.sign =  sign;
        //});

    }

    onSignOk() {
        this.setDataBody = "token=" + this.state.token;
        this.setDataBody += "&params[id]=" + this.state.element.id;
        this.setDataBody += "&params[sign]=" + this.sign;
        

        //console.log(this.setDataBody);
        this.setDataAdress = localStorage.getItem('server') + '/dates/sign';

        this.setState({
            setDataAdress: this.setDataAdress,
            setDataBody: this.setDataBody
        });

    }

    render() {
        //const element = this.props.element;

        if(this.state.isloading) {
            //console.log('AUFTRAG', this.state.element)
            var signField = <ExSignature onSign={this.onSign} />;
            var signButton = <Button
            color="primary"
            className="SignButton"
            onClick={this.onSignOk}
            style={{width: '100%'}}
            >OK</Button>;
            if(this.state.element.sign) {
                signField = <img src={localStorage.getItem('imageserver') + '/' + this.state.token + '/' + this.state.element.sign} className="signature" width="100" height="50" alt="base64 test"/>;
                //signField = <h5>Unterschrieben</h5>;
                signButton = '';
            }

           //console.log(this.state.element);
           //console.log(this.state.formData.artikel);
            return (
                <Form>
                    <ModalHeader className='bg-warning' onHide={this.props.loadData} closeButton>
                        <div className='h4'>SERVICE-AUFTRAG / QUITTUNG</div>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col className="fs-5" sm="7">
                                {this.state.element.name}<br/>
                                {this.state.element.street}<br/><br/>
                                {this.state.element.zip} {this.state.element.city}<br/>
                            </Col>
                            <Col sm="2" className='text-end fs-5'>
                                Datum<br/><br/>
                                KundenNr.<br/>
                                Telefon<br/>
                                Handy<br/>
                                Email<br/>
                            </Col>
                            <Col sm="3" className='fs-5'>
                                : {this.state.element.order_date}<br/><br/>
                                : {this.state.element.debitor_id}<br/>
                                : {this.state.element.telefon}<br/>
                                : {this.state.element.mobil}<br/>
                                : {this.state.element.mailaddress}<br/>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col sm="8" className='fs-5'>
                                <strong className='fs-5'>SERVICE-AUFTRAG / QUITTUNG</strong><br/>
                                <strong className='fs-5'>Nr.: {this.state.element.order_sid}</strong><br/>
                                (bei Rückfragen bitte angeben!)<br/><br/>
                                <strong className='fs-5'>Auftrag / Fehlerbeschreibung</strong><br/>
                                {this.state.element.order}<br/>
                            </Col>
                            <Col sm="1" className='fs-5'>
                                <br/>
                                <strong className='fs-5'>Datum</strong><br/>
                                <br/>
                                <strong className='fs-5'>Ankunft</strong><br/>
                                <strong className='fs-5'>Abfahrt</strong><br/>
                            </Col>
                            <Col className="text-right fs-5" sm="3">
                                <br/>
                                <strong className='fs-5'>: {this.state.element.order_date}</strong><br/>
                                <br />
                                <strong className='fs-5'>: {this.state.element.stempstart} Uhr</strong><br/>
                                <strong className='fs-5'>: {this.state.element.stempend} Uhr</strong><br/>
                                <br/>
                            </Col>
                        </Row>
                        <hr />
                        <div style={{ maxHeight: "650px", overflowY: "auto"}}>
                            <Table striped>
                                <thead style={{ position: "sticky",top: "0" }}>
                                    <tr>
                                        <th className='fs-5' style={{width: '50px'}}>#</th>
                                        <th colSpan={1} className='fs-5'>Arbeit / Marterial</th>
                                        <th style={{width: '100px'}} className="text-end fs-5">Menge</th>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.element.workOrder.map((item, index) => {
                                            const x = 1.0;
                                            const addMenge = parseInt(item.menge) + x;
                                            const subMenge = item.menge - x;
                                            return (
                                                <tr key={index}>
                                                    <th scope="row" className='fs-5'>{index + 1}</th>
                                                    {/* <td className='fs-5' style={{width: '120px'}}>{ item.typ === 'ARTIKEL' ? item.artnr : item.typ}</td> */}
                                                    <td className='fs-5'>{item.bezeichnung}</td>
                                                    <td className="text-end fs-5">
                                                        { this.state.element.sign === null && item.typ === 'ARTIKEL' && item.menge > 1 ? <i className="bi bi-dash-circle-fill text-info pe-3 fs-4" onClick={this.updatePosition} line={index} menge={subMenge}></i> : ''}
                                                        { item.typ === 'TEXT' ? '' : item.menge}
                                                        { this.state.element.sign === null && item.typ === 'ARTIKEL' ? <i className="bi bi-plus-circle-fill text-info ps-3 fs-4" onClick={this.updatePosition} line={index} menge={addMenge}></i> : ''}
                                                    </td>
                                                    <td style={{width: '36px'}} className="text-start fs-5"> {item.einheit}</td>
                                                    <td style={{width: '10px'}} className='text-end'>
                                                        { this.state.element.sign === null && item.typ !== 'KFZ' && item.typ !== 'LOHN' ? <i className="bi bi-x-square-fill text-danger fs-4" onClick={this.deletePosition} do='delete' line={index} menge={item.menge}></i> : ''}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                            
                                </tbody>

                                {/* <tfoot>
                                    <tr>
                                        <th className='col-md-1' scope="row"></th>
                                        <td></td>
                                        <td className="col-md-2 text-end"></td>
                                        <td className="col-md-2 text-end">Summe Netto</td>
                                        <td className="col-md-2 text-end"></td>
                                    </tr>
                                    <tr>
                                        <th scope="row"></th>
                                        <td></td>
                                        <td className="text-end"></td>
                                        <td className="text-end">MwSt.</td>
                                        <td className="text-end"></td>
                                    </tr>                        
                                    <tr>
                                        <th scope="row"></th>
                                        <td></td>
                                        <td className="text-end"></td>
                                        <td className="text-end">Gesamt Brutto</td>
                                        <td className="text-end"></td>
                                    </tr>                            
                                </tfoot> */}

                            </Table>
                        </div>
                    </ModalBody>
                    <ModalBody className={localStorage.getItem('clienttype') === 'desktop' ? '' : "fixed-bottom "}  style={{height: "250px", backgroundColor: '#eee'}}>
                        {
                            this.state.element.sign === null && this.state.closed === false
                            ? <Tabs
                            id="controlled-tab-example"
                            activeKey={this.state.tab}
                            onSelect={(k) => this.setState({tab: k})}
                            className="mb-3 rounded"
                            fill
                            >
                                <Tab eventKey="text" title="Leistungstext" >
                                    <Row>
                                        <Col xs={10}>
                                            <Form.Control 
                                                size="sm"
                                                type="text" 
                                                name="leistungstext"
                                                autoComplete='off'
                                                placeholder="Leistungstext" 
                                                onChange={this.addText}
                                                value={this.state.newText}
                                                //isInvalid={!this.state.formData.check.artikel && this.state.formData.check.artikel !== null}
                                                className="form-control-lg p-0"
                                            />
                                        </Col>
                                        <Col xs={2} className='text-end'>
                                            <Button
                                                size='md'
                                                color="primary"
                                                onClick={this.submitText}
                                                style={{width: '100%'}}
                                            >hinzufügen</Button>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="artikel" title="Artikel">
                                    <Row>
                                        <Col>
                                            <Form.Control 
                                                size="sm"
                                                type="text" 
                                                name="artikelnr"
                                                autoComplete='off'
                                                placeholder="Artikel Nr." 
                                                onChange={this.addArtikel}
                                                value={this.state.newArtikel}
                                                //isInvalid={!this.state.formData.check.artikel && this.state.formData.check.artikel !== null}
                                                className="form-control-lg p-0"
                                                disabled={this.state.newArtikel === 11}
                                            />
                                        </Col>
                                        <Col>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="rezepte" title="Textauswahl">
                                    <p className='fs-4 text-center'>Textauswahl folgt</p>
                                </Tab>

                                <Tab eventKey="artikelsuche" title="Artikel suchen">
                                    <p className='fs-4 text-center'>Artikel suchen folgt</p>
                                </Tab>
                            </Tabs>
                            : ''
                        }
                        {
                            this.state.closed === true
                            ?   <div>
                                    <p className='fs-5'>Unterschrift</p>
                                    <Row className={localStorage.getItem('clienttype') === 'desktop' ? 'p-3 m-3' : 'p-3 m-3 fixed-bottom'}>
                                        <Col xs={4}></Col>
                                        <Col xs={6}>{signField}</Col>
                                        <Col xs={2}>{signButton}</Col>
                                    </Row>
                                </div>
                            : <Button 
                                className='p-3 m-3 fixed-bottom' 
                                style={{width: 'calc(100% - 3)'}} 
                                onClick={() => this.setState({closed: true})}
                                >Abschließen & Unterschrift</Button>
                        }
                                                                               
                    </ModalBody>
                </Form>
            )
        } else {
                
            return (<Alert color="danger">Leider ist die Verbindung verloren gegangen oder ein Timeout aufgetreten.<br /><Button onClick={() => {window.location.reload(false)}} >RELOAD</Button></Alert>)
        }
        


    }
}
