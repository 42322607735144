import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';

export default class PresetSelectPerson extends Component {

    constructor(props) {
        super(props);

        this.onSetPerson = this.onSetPerson.bind(this);
    }

    onSetPerson(event) {
        this.props.onChange(event.target.value, event.target.dataset);
    }

    render() {
        //console.log("components/calendar/components/PresetSelectPerson", this.props.personDefault);
        //console.log('PresetSelectPerson', this.props);
        var personDefault;
        if(this.props.personDefault) {
            personDefault = this.props.personDefault;
        } else {
            personDefault = 0;
        }

        const pf = {user_primary: 1, user_secondary: 2};

        const key = (this.props.calendarId * 10000 + this.props.personDaytime + 100 ) + pf[this.props.personField];
        //console.log(key);

        return (
            <Form.Select
                key={key}
                name={this.props.personField}
                size="sm"
                value={personDefault}
                onChange={this.onSetPerson} 
                data-daytime={this.props.personDaytime} 
                data-day={this.props.personDate}
                data-cal={this.props.calendarId}
                data-field={this.props.personField}
                disabled={this.props.personDisabled}
                style={{border:'0px !important',borderRadius:'0px',height: '100%'}}
            >                
                <option value="0">{this.props.disabled}</option>
                {this.props.personList.map((person, index) => {
                    var serviceAbt = person.abteilung[this.props.personDaytime] === '6' ? ' (Service)' : '';
                    if ((person.taetigkeit[this.props.personDaytime] === '0' || person.taetigkeit[this.props.personDaytime] === '7') && ((this.props.personField === 'user_primary' && person.drivinglicense === '1') || this.props.personField === 'user_secondary')) {
                        if (personDefault && personDefault === person.id) {
                            return (<option value={person.id} key={this.props.personField + (this.props.calendarId * 1000 + index)}>@ {person.fullname}{serviceAbt}</option>);
                        } else if (personDefault !== person.id && this.props.personUsed[this.props.personDaytime].includes(person.id)) {
                            return (<option value={person.id} key={this.props.personField + (this.props.calendarId * 1000 + index)}># {person.fullname}{serviceAbt}</option>);
                        } else {
                            return (<option value={person.id} key={this.props.personField + (this.props.calendarId * 1000 + index)}>{person.fullname}{serviceAbt}</option>);
                        } 
                    } else {
                        return null;
                    }
                })}
            </Form.Select>
        );
    }
}
