import React, { Component } from 'react';
import Card from "react-bootstrap/Card";
import CardHeader from "react-bootstrap/CardHeader";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";

import SelectCalendar from '../SelectCalendar';
import SelectDay from '../SelectDay';
import SelectTime from '../SelectTime';

export default class SparepartList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            time: Date.now(),
            response: [],
            count: 0,
            token: null,
            myHash: null,
            dates: [],
            newDate: []
        };
        this.nocode = '';
        this.timeOut = null;
        this.dates = null;

        this.handleChange = this.handleChange.bind(this);
        this.handleCalChange = this.handleCalChange.bind(this);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.onAddDateCopy = this.onAddDateCopy.bind(this);
        
    }

    _fetchData = async () => {
        clearTimeout(this.timeOut);
        var body = "token=" + this.token;

        if(this.state.fetchDate) {
            body += `&params[date]=` + this.state.fetchDate
        }
        body += "&params[hash]=" + this.state.myHash;

        const response = await fetch(localStorage.getItem('server') + '/dates/sparepart', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },            
            method: 'POST',
            body: body,
        });
        const responseJson = await response.json();


        if(responseJson.status && responseJson.data !== 'nochange') {
            //console.log('SparepartList_hash', responseJson)
            var dates = [];
            
            Object.keys(responseJson.data.dates).forEach(key => {
                dates[key] = responseJson.data.dates[key].calendar;
              });

            this.dates = dates;
            //console.log('responseJson',dates);

            this.setState({ 
                response: responseJson,
                dates: dates,
                time: Date.now(), 
                isLoading: true, 
                myHash: responseJson.parameters.dataHash
            });
        }
        this.timeOut = setTimeout(() => {
            this.updateTime();
        }, 60000);
        
    };    

    _setData = async () => {
        //console.log(this.setDataAdress, this.setDataBody)

        // eslint-disable-next-line no-unused-vars
        const response = await fetch(this.setDataAdress, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },            
            method: 'POST',
            body: this.setDataBody,
        });

        //const responseJson = await response.json();

        //console.log("_setData",responseJson);

        //this._fetchData();
        this.setState({ 
            isLoading: true,
            dates: [],
            newDate: []
        })

    };


    onAddDateCopy(e) {

        //console.log('onCopyDate',e,  e.target[e.target.value].attributes);

        const event = e.target[e.target.value].attributes;

        this.setDataAdress = localStorage.getItem('server') + '/dates/addDate';

        this.setDataBody = "token=" + this.token;
        this.setDataBody += "&params[id]=" + event.termid.value;
        this.setDataBody += "&params[date]=" + event.date.value;
        this.setDataBody += "&params[daytime]=" + event.daytime.value;
        this.setDataBody += "&params[calendar]=" + event.calid.value;
        this.setDataBody += "&params[runtime]=" + event.runtime.value;
        

        //console.log('onAddDate', this.setDataBody);
        
        this._setData();
    }

    componentDidUpdate() {
        this._fetchData();
    }

    updateTime() {
        this.setState({time: Date.now()});
    }

    componentDidMount() {
        //console.log("SparepartList");
        if(sessionStorage.getItem('myService')) {
            const response = sessionStorage.getItem('myService');
            const responseJson = JSON.parse(response);
            this.token = responseJson.Ergebnis;
            this.setState({
                token: responseJson.Ergebnis,
            });
        }        
    }    

    componentWillUnmount() {
        clearTimeout(this.timeOut);
    }


    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleCalChange(listid, id, value) {
        var dates = this.state.dates;
        //console.log('handleCalChange',dates);

        dates[listid] = value;

        //console.log(listid, id, value);

        //this.setState({dates: dates});
        this.setState({
            dates: dates
        });

    }

    handleDayChange(e, listid) {

       //console.log(listid, e);

        const event = e.target[e.target.value].attributes;

        
        this.setState({
            newDate: {
                [listid]: {
                id:         event.termid.value,
                date:       event.date.value,
                daytime:    event.daytime.value,
                calendar:   event.calid.value,
                runtime:    event.runtime.value,
                start: null             
                }
            }
        });
        
    }

    handleTimeChange(e, listid) {

      //console.log(this.state.newDate[listid], e.target.value)

        const event = this.state.newDate[listid];


        this.setDataAdress = localStorage.getItem('server') + '/dates/addDate';

        this.setDataBody = "token=" + this.token;
        this.setDataBody += "&params[id]=" + event.id;
        this.setDataBody += "&params[date]=" + event.date;
        this.setDataBody += "&params[daytime]=" + event.daytime;
        this.setDataBody += "&params[calendar]=" + event.calendar;
        this.setDataBody += "&params[runtime]=" + event.runtime;
        this.setDataBody += "&params[start]=" + e.target.value;
        

       //console.log('onAddDate', this.setDataBody);
        
        this._setData();

    }

    render() {

        var spareList;

        if(this.state.isLoading) {

            const d = this.state.response;
            //console.log('dates ',this.state.dates);
           //console.log('d.data ',d.data);
            var table_content = [];
            var calendar = d.data.calendar;
            const openday = d.data.openday;
            const opentime = d.data.opentime;

           //console.log(this.state.newDate);

            if(d.data) {
                table_content = d.data;
            }

            
            spareList = table_content.dates.map((element, index) => {
                let newDate = [];
                if(this.state.newDate[index]) {
                    newDate = this.state.newDate[index];
                }

                return (
                    <tr key={index}>
                        <td>{element.date_id}</td>
                        <td>{element.debitor_firstname} {element.debitor_name}</td>
                        <td>{element.debitor_address}, {element.debitor_zipcode} {element.debitor_city}</td>
                        <td>{element.description}</td>
                        <td className='text-center'>{calendar[element.calendar].lable}</td>
                        <td className='text-center'>{element.runtime}</td>
                        <td><SelectCalendar handleCalChange={this.handleCalChange} listid={index} id={element.date_id} cal={element.calendar} /></td>
                        <td><SelectDay handleDayChange={this.handleDayChange} listid={index} id={element.date_id} runtime={element.runtime} openday={openday[this.state.dates[index]]} cal={this.state.dates[index]} /></td>
                        <td><SelectTime handleTimeChange={this.handleTimeChange} listid={index} size="default" opentime={opentime} datanew={newDate} /></td>
                    </tr>
                )
            })

        } else {
            spareList =  <tr>
                            <td>
                                <Spinner >
                                Loading...
                                </Spinner>
                            </td>
                        </tr>
            
        }

        return (
            <Card>
                <CardHeader className='h3'>
                    Ersatzteil- und Folgeaufträge
                </CardHeader>
                <Table hover>
                    <thead>
                        <tr>
                            <th colSpan={6}></th>
                            <th className='text-center' colSpan={3}>Folgetermin</th>
                        </tr>
                        <tr>
                            <th style={{width: '5%'}}>#</th>
                            <th style={{width: '13%'}}>Kunde</th>
                            <th style={{width: '18%'}}>Adresse</th>
                            <th style={{width: '30%'}}>Auftrag</th>
                            <th className='text-center' style={{width: '5%'}}>Kalender</th>
                            <th className='text-center' style={{width: '5%'}}>Min</th>
                            <th className='text-center' style={{width: '5%'}}>Kalender</th>
                            <th className='text-center' style={{width: '10%'}}>Termin</th>
                            <th className='text-center' style={{width: '5%'}}>Zeit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {spareList}
                    </tbody>
                </Table>
            </Card>
        )
    }
}