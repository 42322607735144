import React, {Component} from 'react';
import Form from 'react-bootstrap/Form';

export default class SelectDay extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        if(event.target.value !== 'false' && this.props.listid !== 'undefined') {
           //console.log(this.props.listid, this.props.id, event.target.value, event.target[event.target.value].attributes);
            this.props.handleDayChange(event, this.props.listid);
        } else if(event.target.value !== 'false') {
            //console.log(this.props.listid, this.props.id, event.target.value, event.target[event.target.value].attributes);
            this.props.handleDayChange(event);
        }
    }

    render() {

        const daytime= {1: 'vorm.', 2: 'nachm.'};

        //console.log('openday', this.props.openday);
        let bsSize = "sm";


        if(this.props.openday) {
            const openday = this.props.openday;

            if(this.props.bsSize) {
                bsSize = this.props.bsSize;
            }
            return (
                
                <Form.Select
                    size={bsSize}
                    id="calendarday"
                    name="start"
                    type="select"
                    onChange={this.handleChange} 
                    defaultValue=''
                    

                >
                    <option value='false'>wählen</option>
                    {
                        openday.map((cal, i) => {
                            return(<option key={i} value={i + 1} date={cal.date} daytime={cal.daytime} termid={this.props.id} calid={this.props.cal}  runtime={this.props.runtime} >{cal.dateshort}, {daytime[cal.daytime]} ({cal.open})</option>)
                        })
                    }
                </Form.Select>        
            )
        }


    }


}