import React, {Component} from 'react';
import Form from 'react-bootstrap/Form';

export default class SelectTime extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) { //
        if(event.target.value !== 'false' && this.props.listid !== 'undefined') {
            //console.log(this.props.listid, this.props.id, event.target.value, event.target[event.target.value].attributes);
            this.props.handleTimeChange(event, this.props.listid);
        } else if(event.target.value !== 'false') {
            //console.log(this.props.listid, this.props.id, event.target.value, event.target[event.target.value].attributes);
            this.props.handleTimeChange(event);
        }
    }

    render() {

        //console.log('SelectTime', this.props);
        let bsSize = "sm";


        if(this.props.opentime && this.props.datanew.calendar && this.props.datanew.date && this.props.datanew.daytime) {
            
            const opentime = this.props.opentime[this.props.datanew.calendar][this.props.datanew.date][this.props.datanew.daytime];                
            

            //console.log('opentime', opentime);

            if(this.props.bsSize) {
                bsSize = this.props.bsSize;
            }
            return (
                
                <Form.Select
                    bsSize={bsSize}
                    id="calendartime"
                    name="start"
                    type="select"
                    onChange={this.handleChange} 
                    defaultValue=''
                    

                >
                    
                    <option value='false'>wählen</option>
                    {
                        opentime.map((sel, indexs) => {
                            return (
                                <option key={indexs} value={sel[1]} disabled={sel[2]}>{sel[1]}</option>
                            )
                        })
                    }
                </Form.Select>        
            )
        }


    }


}