import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';

export default class ServiceConfig extends Component {

    constructor(props) {
        super(props);

        this.state = {
            time: Date.now(),
            response: [],
            count: 0,
            hash: null,
            token: null,
            searchString: ''
        };
        this.searchString = '';
        this.nocode = '';
    }


    render() {

        const productInfo = JSON.parse(sessionStorage.getItem('myProductInfo'));
       //console.log('ServiceConfig', productInfo);
        let xId = [];

        return (
            <Container fluid >
                <Row>
                    <Col xs={1}>

                    <Nav defaultActiveKey="/home" className="flex-column">
      <Nav.Link href="/home">Artikelgruppen</Nav.Link>
      <Nav.Link eventKey="link-1">Kalender</Nav.Link>
      <Nav.Link eventKey="link-2">Link</Nav.Link>
      <Nav.Link eventKey="disabled" disabled>
        Disabled
      </Nav.Link>
    </Nav>


                        <ButtonGroup vertical size='lg'>
                            <Button className='text-start'>Artikelgruppen</Button>
                            <Button className='text-start'>Kalender</Button>



                        </ButtonGroup>
                    </Col>
                    <Col xs={11}>
                        <Accordion defaultActiveKey="1">

                            {
                                Object.keys(productInfo).map((celement, index) => {
                                   //console.log(productInfo[celement])

                                    return (
                                        <Accordion.Item eventKey={index} key={index}>
                                            <Accordion.Header>{index}</Accordion.Header>
                                            <Accordion.Body>
                                            <Table striped bordered hover size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>Typ</th>
                                                        <th>Name</th>
                                                        <th>Platzhalter</th>
                                                        <th>Text / Option</th>
                                                        <th style={{width: '50px'}} className="text-center"><i className="bi bi-check2-square h3"></i></th>
                                                        <th style={{width: '50px'}} className="text-center"><i className="bi bi-truck h3"></i></th>
                                                        <th style={{width: '50px'}} className="text-center"><i className="bi bi-wrench-adjustable-circle h3"></i></th>
                                                        <th style={{width: '50px'}} className="text-center"><i className="bi bi-plugin h3"></i></th>
                                                        <th style={{width: '50px'}} className="text-center"><i className="bi bi-recycle h3"></i></th>
                                                        <th style={{width: '50px'}} className="text-center"><i className="bi bi-arrow-down-up h3"></i></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                            {
                                                productInfo[celement].map((item, cindex) => {
                                                    
                                                    if(xId.includes(item['id'])) {
                                                        return null;
                                                    }
                                                    xId.push(item['id']);

                                                    return (
                                                        <tr key={cindex}>
                                                            <td>{item['typ']}</td>
                                                            <td>{item['name']}</td>
                                                            <td>{item['placeholder']}</td>
                                                            <td>{item['defaultvalue']}</td>
                                                            <td className="text-center">
                                                                <Form.Check 
                                                                    name="check" 
                                                                    data-key={index} 
                                                                    type="checkbox" 
                                                                    defaultChecked={item.check}
                                                                    onChange={this.changeArtikel} />
                                                            </td>                                                            
                                                            <td className="text-center">
                                                                <Form.Check 
                                                                    name="deliver" 
                                                                    data-key={index} 
                                                                    type="checkbox" 
                                                                    defaultChecked={item.area?.includes('deliver')}
                                                                    onChange={this.changeArtikel} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Check 
                                                                    name="setup" 
                                                                    data-key={index} 
                                                                    type="checkbox" 
                                                                    defaultChecked={item.area?.includes('setup')}
                                                                    onChange={this.changeArtikel} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Check 
                                                                    name="connect" 
                                                                    data-key={index} 
                                                                    type="checkbox" 
                                                                    defaultChecked={item.area?.includes('connect')}
                                                                    onChange={this.changeArtikel} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Check 
                                                                    name="dispose" 
                                                                    data-key={index} 
                                                                    type="checkbox" 
                                                                    defaultChecked={item.area?.includes('dispose')}
                                                                    onChange={this.changeArtikel} />
                                                            </td>
                                                            <td className='text-center'>{item['order']}</td>
                                                            <td style={{width: '50px'}} className="text-center text-danger"><i className="bi bi-x-circle h3"></i></td>

                                                        </tr>
                                                    )
                                                    
                                                })
                                            }
                                                </tbody>
                                                </Table>                                            
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }       
                        </Accordion>
                    </Col>
                </Row>
            </Container>
          );
    }
}


/*

<DropdownButton
    as={ButtonGroup}
    title="Dropdown"
    id="bg-vertical-dropdown-1"
    className='text-start'
>
    <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
    <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
</DropdownButton>

*/