import React, { Component } from 'react';
import Card from "react-bootstrap/Card";
import CardHeader from "react-bootstrap/CardHeader";
import Table from "react-bootstrap/Table";
import ModalDate from '../modals/ModalDate';



export default class TerminWorkSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            time: Date.now(),
            response: [],
            count: 0,
            hash: null,
            token: null,
            searchString: ''
        };
        this.searchString = '';
        this.nocode = '';

        this.onClickExit = this.onClickExit.bind(this);
    }

    _fetchData = async () => {
        var body = '';

        if(this.props.searchString !== this.searchString) {
            body += `params[searchString]=` + this.props.searchString;
            this.searchString = this.props.searchString;
        }

    
        if(body !== '') {
            body += "&token=" + this.token;

            //console.log('TerminPlaner_fetchData', Date(), body)

            const response = await fetch(localStorage.getItem('server') + '/dates/search', {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },            
                method: 'POST',
                body: body,
            });
            const responseJson = await response.json();

            if(responseJson.serviceUser.OK !== 'NEIN') {
                this.setState({ response: responseJson});
            } else {
                this.onClickExit();
            }

        }

    };

    onClickExit() {
        sessionStorage.clear();
        window.location.href = '/';
    }

    componentDidMount() {
        //console.log("TerminWorkSearch componentDidMount", this.props);
        if(sessionStorage.getItem('myService')) {
            const response = sessionStorage.getItem('myService');
            const responseJson = JSON.parse(response);
            this.token = responseJson.Ergebnis;
            this.setState({
                searchString: this.props.searchString,
                response: []
            });
        }
    }

    componentDidUpdate() {
        //console.log("TerminWorkSearch componentDidUpdate", this.props);
        this._fetchData();
    }

    onClickModal(event) {
        //console.log(event.target.dataset);

        this.props.handleModalShowClick(event);
    }    

    render() {
        //console.log("TerminWorkSearch response",this.state.response);
        if(this.state.response.data && this.state.response.serviceUser.OK !== 'NEIN') {
            return (
                <Card style={{top: 42}}>
                    <CardHeader>
                        Austragssuche nach "{this.props.searchString}"
                    </CardHeader>
                    <Table striped hover>
                        <thead style={{position: 'sticky', top: '0', zIndex: 1, backgroundColor: 'white'}}>
                            <tr>
                                <th style={{width: '5%'}}>#</th>
                                <td style={{width: '15%'}}>Kunde</td>
                                <td style={{width: '20%'}}>Adresse</td>
                                <td style={{width: '40%'}}>Auftrag</td>
                                <td style={{width: '5%'}}>Datum</td>
                                <td style={{width: '5%'}}>Status</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.response.data.map((element, index) => {
                                    return (
                                        <ModalDate modtyp="listWorkSearch" key={index} element={element} />
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Card>
            )
        } else {
            return (
                <>test</>
            )
        }

    }
}