import React, { Component } from 'react';
import ExModalDate from '../../modals/ModalDate';
import ExModalDateAdd from '../../modals/ModalDateAdd'
import PresetSelectCity from './components/PresetSelectCity';
import PresetSelectPerson from './components/PresetSelectPerson';
import ExModalDateGeo from '../../modals/ModalDateGeo';

export default class CalendarItems extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fromId: null,
            toId: null,
            toTime: null,
            toCal: null,
            height: 30,
        }
        this.setDataAdress = null;
        this.datesOpen = {
            1: null,
            2: null
        };


        this.timeTable= {
            5:"h0800",
            6:"h0830",
            7:"h0900",
            8:"h0930",
            9:"h1000",
            10:"h1030",
            11:"h1100",
            12:"h1130",
            13:"h1200",
            14:"h1230",
            15:"nPrimary",
            18:"h1300",
            19:"h1330",
            20:"h1400",
            21:"h1430",
            22:"h1500",
            23:"h1530",
            24:"h1600",
            25:"h1630",
            26:"h1700",
            27:"h1730",
            28:"h1800",
            29:"h1830",
            30:"h1900"
        };

    
        this.offsetHeight = 25.0;
        this.handleFrom = this.handleFrom.bind(this);
        this.handleToId = this.handleToId.bind(this);
        this.handleToTime = this.handleToTime.bind(this);
        this.onClickFree = this.onClickFree.bind(this);
        this.handleOnSetComp = this.handleOnSetComp.bind(this);
        this.refHeight = React.createRef();


        

    }

    handleFrom(event) {
        if(this.state.fromId !== event.target.id) {
            //console.log("from", event.target.dataset.calendar, event.target.id, event.target);
            this.setState({
                fromId: event.target.id
            });
        }
    }

    handleToId(event) {
        if(this.state.toId !== event.target.id) {
            //console.log("to id", event.target.dataset.calendar, event.target.id, event.target);
            this.setState({
                toId: event.target.id
            });
        }
    }

    handleToTime(event) {
        //console.log('CalendarItems',event.dataTransfer.getData("id"), event.target.dataset.calendar, event.target.dataset.start);
        this.props.onMoveDate(event);
        if(this.state.toTime !== event.target.id) {
            //console.log("to time", event.target.dataset.calendar, event.target.dataset.key, event.target);
            this.setState({
                toTime: event.target.id
            });
        }
    }

    handleOnSetComp(event) {
        //console.log('handleOnSetComp', event.target);
        this.props.onSetComp(event.target.dataset);
    }
  
    onClickFree(event) {
        //console.log(event.target.dataset);
    }

    componentDidMount() {
        const height = this.divElement.clientHeight + 4;
        //console.log("height", height);
        this.setState({ height });
      }

    render() {
        //console.log("components/planner/CalendarItems");
        //console.log('myservice!!', this.props.myservice);
       //console.log('CalendarItems', this.props);

        var myservice = this.props.myservice;
        var xHours = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];

        const rStufe = ['9','8','7','4','3'];
        const rAbteilung = ['6','8','11']; //11

        var name = '';
        var nameClass = '';
        
        //const xOff = 0;
        var xHeight = 0;
        var ikey;

        const maxHight = 35.0;



        var currentTime = new Date();

        //const year = currentTime.toLocaleString("default", { year: "numeric" });
        //const month = currentTime.toLocaleString("default", { month: "2-digit" });
        //const day = currentTime.toLocaleString("default", { day: "2-digit" });
        //const currentDate = year + '-' + month + '-' + day;



     
        //console.log('PersonList', this.props.personList);
        //console.log('itemDates', this.props.itemDates);
        //console.log('itemDouble', this.props.itemDouble);

        const itemData = this.props.itemData;
        const itemDates = this.props.itemDates;
        const itemDouble = this.props.itemDouble;

        var xRow = 5;
        var yRow = 18;
       //console.log('CalendarData' , itemDates);

       
        return (




                // eslint-disable-next-line array-callback-return
                xHours.map((i, index) => {
                    
                    name = '';
                    nameClass = '';
                    xHeight = 35;
                    var personDefault = 0;
                    var cityDefault = '';

                    var rowStart = null;
                    var rowEnd = null;




                    switch(i) {

                        case 1:

                           //console.log('CalendarItems-' + i, itemData[1], itemData[2], datesOpen);

                            ikey = 'd1' + (this.props.CalendarItem['id'] * 1000) + index;


                            var timeData = '';


                            if(itemData[1] && itemData[2] && itemData[1].dates_open && itemData[2].dates_open) {
                                timeData = '(' + itemData[1].dates_open + '/' + itemData[2].dates_open + ')';
                                this.datesOpen[1] = itemData[1].dates_open;
                                this.datesOpen[2] = itemData[2].dates_open;
                            } else if(itemData[1] && itemData[1].dates_open) {
                                timeData = '(' + itemData[1].dates_open + '/ NB)';
                                this.datesOpen[1] = itemData[1].dates_open;
                            } else if(itemData[2] && itemData[2].dates_open) {
                                timeData = '(NB/' + itemData[2].dates_open + ')';
                                this.datesOpen[1] = itemData[2].dates_open;
                            }


                            //console.log('CalendarItems-' + i, itemData[1], itemData[2], 'datesOpen', this.datesOpen);


                            let dataArray = [];
                            xHours.forEach(item => {
                                // Access individual data from element and add it to the dataArray
                                //dataArray.push(element.data);
                                if(itemDates[item] !== undefined && typeof itemDates[item] !== 'string')  {
                                  //console.log(props.itemDates[item]);
                                  dataArray.push(itemDates[item]);
                                }
                              });


                            if(localStorage.getItem('dev') && (itemData[1] && itemData[1]['user_primary']) && dataArray.length > 0 && rAbteilung.includes(myservice['MitarbeiterAbteilung'])) {

                                return (
                                    <ExModalDateGeo 
                                        key={ikey} 
                                        calId={this.props.CalendarItem['id']} Lable={this.props.Lable + ' ' + timeData} 
                                        itemDates={itemDates}
                                        gridColumnStart= {this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + ''}
                                        gridColumnEnd= {this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + ''}
                                        maxHeight= {maxHight}

                                        />
                                );

                            } else {
                                        /*<div
                                            className="btn plan-top bg-secondary bg-gradient fw-bold text-white fs-5"
                                        ></div>*/
                                return (

                                    


                                        <div 

                                            key={ikey}
                                            className="btn plan-top top-border bg-secondary bg-gradient fw-bold text-white fs-5"
                                            style={{
                                                gridRowStart:'calendar', 
                                                gridRowEnd: 'calendar', 
                                                gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '', 
                                                gridColumnEnd: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '', 
                                                border:'0px !important',
                                                borderRadius:'0px',
                                                maxHeight: {maxHight},
                                                overflow: 'hidden'}}
                                            onClick={this.handleOnSetComp}
                                            data-compdate={this.props.CalendarDate}
                                            data-comptype={this.props.changeType}
                                            data-compcalendarid={this.props.CalendarItem['id']}>
                                            {this.props.Lable + ' ' + timeData}
                                        </div>                                    
                                    


                                );
                            }

                        case 2:
                            
                                //console.log('CalendarItems-' + i, itemData[1]);

                                ikey = 'p1' + (this.props.CalendarItem['id'] * 1000) + index;

                                //console.log(ikey);

                                if(itemData[1] && itemData[1]['user_primary']) {
                                    personDefault = itemData[1]['user_primary'];
                                }

                                if(rStufe.includes(myservice['Stufe']) && rAbteilung.includes(myservice['MitarbeiterAbteilung'])) {
                                    return ( <div ref={ (divElement) => { this.divElement = divElement } } key={i + this.props.ColumnStart + index} 
                                                style={{
                                                    padding:'0px', 
                                                    gridRowStart:'vPrimary', 
                                                    gridColumnStart:  this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '', 
                                                    maxHeight: {maxHight}, 
                                                    overflow: 'hidden' }}
                                                >
                                                <PresetSelectPerson 
                                                    onChange={this.props.onSetPerson} 
                                                    calendarId={this.props.CalendarItem['id']} 
                                                    personField="user_primary" 
                                                    personDefault={personDefault} 
                                                    personList={this.props.personList} 
                                                    personUsed={this.props.personUsed} 
                                                    personDate={this.props.CalendarDate} 
                                                    personDaytime={1} 
                                                    personDisabled={false} 
                                            /></div>
                                            );
                                } else {


                                    if(itemData[1] && (itemData[1]['user_primary'] !== '0')) {
                                        const result = this.props.personList.find(({ id }) => id === itemData[1]['user_primary']);
                                        if(result) {
                                            name = result.shortname;
                                            nameClass = 'plan-top-info';
                                        } else {
                                            name = "2 ACHTUNG!";
                                            nameClass = 'plan-top-info';
                                        }
                                    } else {
                                        name = "";
                                        nameClass = 'plan-none';
                                    }
                                }                                 
                            return (<div ref={ (divElement) => { this.divElement = divElement } } key={ikey} className={nameClass} style={{gridRowStart: 'vPrimary', gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '', maxHeight: {maxHight}, overflow: 'hidden' }}>{name}</div>);


                            
                        case 3:
                            //console.log('CalendarItems-' + i, itemData[1]);

                            ikey = `s1${this.props.CalendarDate}${this.props.CalendarItem['id'] * 100 +index}`;

                            if(itemData[1] && itemData[1]['user_secondary']) {
                                personDefault = itemData[1]['user_secondary'];
                            }


                            if(rStufe.includes(myservice['Stufe']) && rAbteilung.includes(myservice['MitarbeiterAbteilung']) && itemData[1] && itemData[1]['user_primary']) {
                                return ( <div key={i + this.props.ColumnStart + index} style={{padding:'0px', gridRowStart: 'vSecondary', gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}>
                                            <PresetSelectPerson 
                                                onChange={this.props.onSetPerson} 
                                                calendarId={this.props.CalendarItem['id']} 
                                                personField="user_secondary" 
                                                personDefault={personDefault} 
                                                personList={this.props.personList} 
                                                personUsed={this.props.personUsed} 
                                                personDate={this.props.CalendarDate} 
                                                personDaytime={1} 
                                                personDisabled={false} 
                                        /></div> );
                            } else {
                                if(itemData[1] && itemData[1]['user_secondary'] && itemData[1]['user_secondary'] !== '0') {
                                    const result = this.props.personList.find(({ id }) => id === itemData[1]['user_secondary']);
                                    if(result) {
                                        name = result.shortname;
                                        nameClass = 'plan-top-info';
                                    } else {
                                        name = "ACHTUNG!";
                                        nameClass = 'plan-top-info';
                                    }
                                } else {
                                    name = "";
                                    nameClass = 'plan-none';
                                }
                                return (<div key={ikey} className={nameClass} style={{gridRowStart: 'vSecondary', gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}>{name}</div>);    
                            }
        
                            
                        case 4:
                            //console.log('CalendarItems-' + i, itemData[i]);

                            ikey = `c1${this.props.CalendarDate}${this.props.CalendarItem['id'] * 100 +index}`;

                            if(itemData[1] && itemData[1]['city']) {
                                cityDefault = itemData[1]['city'];
                            }

                            if(rStufe.includes(myservice['Stufe']) && rAbteilung.includes(myservice['MitarbeiterAbteilung']) && itemData[1] && itemData[1]['user_primary'] && itemData[1]['user_primary'] !== '0') {
                                return ( <PresetSelectCity 
                                            key={ikey}
                                            onSetCity={this.props.onSetCity}  
                                            calendarId={this.props.CalendarItem['id']} 
                                            cityDefault={cityDefault} 
                                            cityDate={this.props.CalendarDate} 
                                            cityDaytime={1} 
                                            style={{padding:'0px', gridRowStart: 'vCity', gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}
                                        /> );
                            } else {
                                if(itemData[1] && itemData[1]['city'] && itemData[1]['city'] !== '') {
                                    return (<div key={ikey} className="plan-top-info" style={{gridRowStart: 'vCity', gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}>{itemData[1]['city']}</div>);
                                } else {
                                    return (<div key={ikey} className="plan-none" style={{gridRowStart: 'vCity', gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}></div>);
                                }
                            }

                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                            //console.log('CalendarItems-' + i, this.props);

                            var dt = 1;

                            //console.log(i, open[dt][this.datesOpen[dt]], this.datesOpen[dt]);

                            ikey = `t${dt}${this.props.CalendarDate}${this.props.CalendarItem['id'] * 100 +index}`;

                            var thisTime;
                            if(typeof itemDates[i] === 'string') {
                                thisTime = itemDates[i];
                            } else {
                                thisTime = itemDates[i].start;
                            }
                            var thisTime1 = new Date(this.props.CalendarDate + ' ' + thisTime + ':00');

                            if(rAbteilung.includes(myservice['MitarbeiterAbteilung'])) {

                            }
                            if(itemData && itemData[dt] && itemData[dt]['user_primary'] && itemDates && typeof itemDates[i] === 'string' && itemDates[i] !== 'none' && currentTime < thisTime1 && rAbteilung.includes(myservice['MitarbeiterAbteilung'])) {
                                return (
                                    <ExModalDateAdd 
                                        modtyp="planner"
                                        key={ikey} 
                                        timeid={index} 
                                        date={this.props.CalendarDate} 
                                        daytime={dt} 
                                        element={this.props.CalendarItem}
                                        calendarId={this.props.CalendarItem['id']} 
                                        rowStart={i} 
                                        colStart={this.props.ColumnStart} //this.props.ColumnStart
                                        classElement="plan-none"
                                        gridColumnStart= {this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + ''}
                                        gridColumnEnd= {this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + ''}

                                        onDatesInsert={this.props.onDatesInsert}
                                        handleToTime={this.handleToTime} 
                                    />
                                );
                            } else if(itemData && itemData[dt] && itemData[dt]['user_primary'] && itemDates && typeof itemDates[i] === 'string' && itemDates[i] !== 'none' && rAbteilung.includes(myservice['MitarbeiterAbteilung'])) {
                                return (
                                        
                                            <div 
                                                key={ikey} 
                                                className="plan-none" 
                                                style={{gridRowStart: 'h' + itemDates[i].replace(":", ""), gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '', gridColumnEnd: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}
                                            ></div>
                                        );
                            } 

                            if(itemDates && itemDates[i] && itemDates[i].gridEnd) {
                                    //xHeight = this.offsetHeight * (itemDates[i].gridEnd - itemDates[i].gridStart)  + xOff;
                                    xHeight = this.state.height * (itemDates[i].gridEnd - itemDates[i].gridStart);
                                    //console.log('xHeight', this.state.height, xHeight, itemDates[i].gridEnd, itemDates[i].gridStart);
                                    rowStart = itemDates[i].gridStart;
                                    rowEnd = itemDates[i].gridEnd;
                                    if(!rAbteilung.includes(myservice['MitarbeiterAbteilung'])) {
                                        xHeight = 50.0;
                                        rowStart = xRow;
                                        rowEnd = xRow + 2;
                                        xRow = xRow + 2;
                                    }
                                   //console.log("rowStart", rowStart, this.timeTable[rowStart])
                                   //console.log("rowEnd", rowEnd, this.timeTable[rowEnd])

                                    //console.log( itemDouble[i][2], itemDates[i]['id'])
                                    return(
                                        <ExModalDate 
                                            modtyp="planner" 
                                            key={ikey} 
                                            element={itemDates[i]} 
                                            gridStart={this.timeTable[rowStart]}
                                            gridEnd= {this.timeTable[rowEnd]}
                                            colStart={this.props.ColumnStart} 
                                            calendarId={this.props.CalendarItem['id']} 
                                            xHeight={xHeight} 
                                            dindex = {this.props.dindex} 
                                            dFree={this.props.dFree} 
                                            onDatesInsert={this.props.onDatesInsert}
                                            handleFrom={this.handleFrom}
                                            handleToId={this.handleToId}
                                            onUpdate={this.props.onUpdate}
                                            myservice={this.props.myservice} 
                                            gridColumnStart= {this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + ''}
                                            gridColumnEnd= {this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + ''}
                                            doubleDate={ itemDouble && itemDouble[i][2] === itemDates[i]['id'] 
                                                ? " doubleDate" 
                                                : itemDouble && itemDouble[i][3] === itemDates[i]['id'] 
                                                    ? " doubleDateZwei" 
                                                    : itemDouble && itemDouble[i][4] === itemDates[i]['id'] 
                                                    ? " doubleDateDrei" 
                                                    : ""
                                            } 


                                        />
                                    );
                            } else if(itemDates && itemDates[i] && itemDates[i] !== 'none' && rAbteilung.includes(myservice['MitarbeiterAbteilung'])) {
                                return (
                                 
                                            <div 
                                                key={ikey} 
                                                className="plan-locked" 
                                                style={{gridRowStart: 'h' + itemDates[i].replace(":", ""), gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '', gridColumnEnd: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}
                                            ></div>
                                        );
                            }

                            break;
                        case 15:
                            //console.log('CalendarItems-' + i, itemData);
                           //console.log('CalendarItems-' + i, itemData[2]);

                            ikey = `p2${this.props.CalendarDate}${this.props.CalendarItem['id'] * 100 +index}`;


                            if(itemData[2] && itemData[2]['user_primary'] && itemData[2]['user_primary'] !== '0') {
                                if(rStufe.includes(myservice['Stufe']) && rAbteilung.includes(myservice['MitarbeiterAbteilung']) && itemData[2]['user_primary'] !== '0') {
                                    return ( <div key={i + this.props.ColumnStart + index} style={{padding:'0px', gridRowStart: 'nPrimary', gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}>
                                                <PresetSelectPerson 
                                                    onChange={this.props.onSetPerson} 
                                                    calendarId={this.props.CalendarItem['id']} 
                                                    personField="user_primary" 
                                                    personDefault={itemData[2]['user_primary']} 
                                                    personList={this.props.personList} 
                                                    personUsed={this.props.personUsed} 
                                                    personDate={this.props.CalendarDate} 
                                                    personDaytime={2} 
                                                    personDisabled={false} 
                                            />
                                            </div> );
                                } else {
                                    if(itemData[2]['user_primary'] !== '0') {
                                        const result = this.props.personList.find(({ id }) => id === itemData[2]['user_primary']);
                                        if(result) {
                                            name = result.shortname;
                                        } else {
                                            name = "ACHTUNG!";
                                        }
                                    } else {
                                        name = "";
                                        nameClass = 'plan-none';
                                    }
                                    return (<div key={ikey} className="plan-top-info" style={{gridRowStart: 'nPrimary', gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}>{name}</div>);
                                }
        
                                
                            } else {
                                if(rStufe.includes(myservice['Stufe']) && rAbteilung.includes(myservice['MitarbeiterAbteilung'])) {
                                    return ( <div key={i + this.props.ColumnStart + index} style={{padding:'0px', gridRowStart: 'nPrimary', gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}>
                                                <PresetSelectPerson 
                                                onChange={this.props.onSetPerson} 
                                                calendarId={this.props.CalendarItem['id']} 
                                                personField="user_primary" 
                                                personDefault={0} 
                                                personList={this.props.personList} 
                                                personUsed={this.props.personUsed} 
                                                personDate={this.props.CalendarDate} 
                                                personDaytime={2} 
                                                personDisabled={false} 
                                            /></div> );
                                } else {
                                    return (<div key={ikey} className="plan-top-info" style={{gridRowStart: 'nPrimary', gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}>nicht geplant</div>);
                                }
                            }
                        case 16:
                            //console.log('CalendarItems-' + i, itemData);

                            ikey = `s2${this.props.CalendarDate}${this.props.CalendarItem['id'] * 100 +index}`;


                            if(itemData[2] && itemData[2]['user_secondary']) {
                                personDefault = itemData[2]['user_secondary'];
                            }

                            if(rStufe.includes(myservice['Stufe']) && rAbteilung.includes(myservice['MitarbeiterAbteilung']) && itemData[2] && itemData[2]['user_primary'] !== '0') {
                                return ( <div key={i + this.props.ColumnStart + index} style={{padding:'0px', gridRowStart: 'nSecondary', gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}>
                                            <PresetSelectPerson 
                                                key={i + this.props.ColumnStart + index}
                                                onChange={this.props.onSetPerson} 
                                                calendarId={this.props.CalendarItem['id']} 
                                                personField="user_secondary" 
                                                personDefault={personDefault} 
                                                personList={this.props.personList} 
                                                personUsed={this.props.personUsed} 
                                                personDate={this.props.CalendarDate} 
                                                personDaytime={2} 
                                                personDisabled={false} 
                                                style={{padding:'0px', gridRowStart: 'nSecondary', gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}
                                        /></div> );
                            } else {
                                if(itemData[2] && itemData[2]['user_secondary'] && itemData[2]['user_secondary'] !== '0') {
                                    const result = this.props.personList.find(({ id }) => id === itemData[2]['user_secondary']);
                                    if(result) {
                                        name = result.shortname;
                                        nameClass = 'plan-top-info';
                                    } else {
                                        name = "ACHTUNG!";
                                        nameClass = 'plan-top-info';
        
                                    }
                                } else {
                                    name = "";
                                    nameClass = 'plan-none';
                                }
                                return (<div key={ikey} className={nameClass} style={{gridRowStart: 'nSecondary', gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}>{name}</div>);    
                            }
        
                        case 17:
                            //console.log('CalendarItems-' + i, itemData[2]);

                            ikey = `c2${this.props.CalendarDate}${this.props.CalendarItem['id'] * 100 +index}`;

                            if(itemData[2] && itemData[2]['city']) {
                                cityDefault = itemData[2]['city'];
                            }

                            if(rStufe.includes(myservice['Stufe']) && rAbteilung.includes(myservice['MitarbeiterAbteilung']) && itemData[2] && itemData[2]['user_primary'] && itemData[2]['user_primary'] !== '0') {
                                return ( <PresetSelectCity 
                                                key={ikey} 
                                                onSetCity={this.props.onSetCity}  
                                                calendarId={this.props.CalendarItem['id']} 
                                                cityDefault={cityDefault} 
                                                cityDate={this.props.CalendarDate} 
                                                cityDaytime={2} 
                                                style={{padding:'0px', gridRowStart: 'nCity', gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}
                                        /> );
                            } else {
                                if(itemData[2] && itemData[2]['city'] && itemData[2]['city'] !== '') {
                                    return (<div key={ikey} className="plan-top-info" style={{gridRowStart: 'nCity', gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}>{itemData[2]['city']}</div>);
                                } else {
                                    return (<div key={ikey} className="plan-none" style={{gridRowStart: 'nCity', gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}></div>);
                                }                                    
                            }

                        case 18:
                        case 19:
                        case 20:
                        case 21:
                        case 22:
                        case 23:
                        case 24:
                        case 25:
                        case 26:
                        case 27:
                        case 28:
                        case 29:
                        case 30:
                            //console.log('CalendarItems-' + i, itemData, itemDates);

                            ikey = `t2${this.props.CalendarDate}${this.props.CalendarItem['id'] * 100 +index}`;


                            if(typeof itemDates[i] === 'string') {
                                thisTime = itemDates[i];
                            } else {
                                thisTime = itemDates[i].start;
                            }
                            var thisTime2 = new Date(this.props.CalendarDate + ' ' + thisTime + ':00');


                            if(itemData && itemData[2] && itemData[2]['user_primary'] && itemDates && typeof itemDates[i] === 'string' && itemDates[i] !== 'none' && currentTime < thisTime2 && rAbteilung.includes(myservice['MitarbeiterAbteilung'])) {
                                return (
                                    <ExModalDateAdd 
                                        modtyp="planner"
                                        key={ikey} 
                                        timeid={index} 
                                        date={this.props.CalendarDate} 
                                        daytime='2' 
                                        element={this.props.CalendarItem}
                                        calendarId={this.props.CalendarItem['id']} 
                                        rowStart={i} 
                                        colStart={this.props.ColumnStart} //this.props.ColumnStart
                                        classElement="plan-none"  
                                        gridColumnStart= {this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + ''}
                                        gridColumnEnd= {this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + ''}                                        
                                        onDatesInsert={this.props.onDatesInsert}
                                        handleToTime={this.handleToTime} 
    
                                    />
                                );
                            } else if(itemData && itemData[2] && itemData[2]['user_primary'] && itemDates && typeof itemDates[i] === 'string' && itemDates[i] !== 'none' && rAbteilung.includes(myservice['MitarbeiterAbteilung'])) {
                                return (<div key={ikey} className="plan-none time" style={{gridRowStart: 'h' + itemDates[i].replace(":", ""), gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}></div>);
                            } else if(itemDates && itemDates[i] && itemDates[i].gridEnd) {
                                    //xHeight = this.offsetHeight * (itemDates[i].gridEnd - itemDates[i].gridStart) + xOff;
                                    xHeight = this.state.height * (itemDates[i].gridEnd - itemDates[i].gridStart) + 1;
                                    //console.log('xHeight', this.state.height, xHeight, itemDates[i].gridEnd, itemDates[i].gridStart);
                                    rowStart = itemDates[i].gridStart;
                                    rowEnd = itemDates[i].gridEnd;
                                    if(!rAbteilung.includes(myservice['MitarbeiterAbteilung'])) {
                                        xHeight = 50.0;
                                        rowStart = yRow;
                                        rowEnd = yRow + 2;
                                        yRow = yRow + 2;
                                    }
                                   //console.log("rowStart", rowStart, this.timeTable[rowStart])
                                   //console.log("rowEnd", rowEnd, this.timeTable[rowEnd])
                                    return(
                                        <ExModalDate 
                                            modtyp="planner" 
                                            key={ikey} 
                                            element={itemDates[i]} 
                                            colStart={this.props.ColumnStart} 
                                            gridStart={this.timeTable[rowStart]}
                                            gridEnd={this.timeTable[rowEnd]}
                                            calendarId={this.props.CalendarItem['id']}  
                                            xHeight={xHeight} 
                                            dindex = {this.props.dindex} 
                                            dFree={this.props.dFree} 
                                            doubleDate={ itemDouble && itemDouble[i][2] === itemDates[i]['id'] 
                                                            ? " doubleDate" 
                                                            : itemDouble && itemDouble[i][3] === itemDates[i]['id'] 
                                                                ? " doubleDateZwei" 
                                                                : itemDouble && itemDouble[i][4] === itemDates[i]['id'] 
                                                                ? " doubleDateDrei" 
                                                                : ""
                                                        } 
                                            onDatesInsert={this.props.onDatesInsert}
                                            handleFrom={this.handleFrom}
                                            handleToId={this.handleToId}
                                            myservice={this.props.myservice} 
                                            gridColumnStart= {this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + ''}
                                            gridColumnEnd= {this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + ''}
                                        />
                                    );
                            } else if(itemDates && itemDates[i] && itemDates[i] !== 'none' && rAbteilung.includes(myservice['MitarbeiterAbteilung'])) {
                                return (<div key={ikey} className="plan-locked" style={{gridRowStart: 'h' + itemDates[i].replace(":", ""), gridColumnStart: this.props.type === 'day' ? 'cd' + this.props.CalendarItem['id'] + '' : 'cd' + this.props.CalendarDate.replaceAll("-", "") + '' }}></div>);
                            }
                            break;
                        default:
                            break;
                        }
                })                        
            
        );

    }


}