import React, { Component } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';

export default class TableHeadWeek extends Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    render() {
        //console.log('TableHeadWeek', this.props)
        const action = this.props.action;

        return ( 
            <Card.Header tag="h5" id="week">
                <div className='h4'>
                    <Badge className='mb-2'>{this.props.badge}</Badge> {this.props.lable} <small> {this.props.lablesmall}</small>
                    <div className="pull-right">
                        <ButtonGroup size="md" className='mb-2'>
                            <Button variant="outline-info" onClick={this.props.onChangesDay} value={action.previousWeek}>KW{action.previousKW}</Button>
                            <Button variant="outline-info">KW{action.thisKW.kw}</Button>
                            <Button variant="outline-info" className="me-2" onClick={this.props.onChangesDay} value={action.nextWeek}>KW{action.nextKW}</Button>
                        </ButtonGroup>
                        <Button size="md" className='mb-2' color="success" onClick={this.props.onChangesDay} value={action.now.date}>Aktuell</Button>
                    </div>
                </div> 
            </Card.Header>
        );        
    }
}