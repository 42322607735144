import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';

export default class PresetSelectCity extends Component {

    constructor(props) {
        super(props);

        this.onSetCity = this.onSetCity.bind(this);
    }    

    onSetCity(event) {
        this.props.onSetCity(event.target.value, event.target.dataset);
    }    

    render() {
        //console.log('PresetSelectCity',this.props.cityDefault)

        return (
            <div style={this.props.style}>

                <Form.Select
                    name="select"
                    size="sm"
                    value={this.props.cityDefault}
                    onChange={this.onSetCity} 
                    data-daytime={this.props.cityDaytime} 
                    data-day={this.props.cityDate}
                    data-cal={this.props.calendarId}
                    style={{border:'0px !important',borderRadius:'0px',height: '100%'}}
                >
                    <option value="">offen</option>
                    <option value="Ahaus">Ahaus</option>
                    <option value="Coesfeld">Coesfeld</option>
                    <option value="Borken">Borken</option>
                    <option disabled>----------</option>
                    <option value="disabled">nicht besetzt!</option>
                </Form.Select>
            </div>

        );        
    }
}