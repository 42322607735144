import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import DateSortGeo from '../components/DateSortGeo';

const ExModalDateGeo = (props) => {
  //console.log("modals/ModalDate");
    const {
      className
    } = props;
  
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    

    return (
        <div
            className="btn plan-top bg-secondary bg-gradient fw-bold text-white fs-5"
            onClick={toggle}
            style={{
                cursor: 'pointer', 
                maxHeight: props.maxHight,
                gridColumnStart: props.gridColumnStart,
                gridColumnEnd: props.gridColumnEnd,
                gridRowStart:'calendar', 
                border:'0px !important',
                borderRadius:'0px',
            }}
        >
        
        {props.Lable} <i className="bi bi-pin-map pull-right m-1 text-warning"></i>
            <Modal size='xl' show={modal} toggle={toggle} className={className} onClosed={props.onDatesInsert} >
                <DateSortGeo {...props} />
            </Modal>

        </div>
                                


    );
  
}

export default ExModalDateGeo;