import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';

export default function ViewProduct(props) {

    const formData = props.formData;
   //console.log("ViewProduct formData", formData)
    return (
        <Row className="mb-3">
            <Col>
                <h5>Lieferung</h5>
                <Table className="m-2" striped bordered hover >
                    <thead className="mb-2" style={{height: '20px'}}>
                        <tr>
                        <th style={{width: '110px'}}>Artikel</th>
                        <th style={{width: '200px'}}>Bezeichnung</th>
                        <th style={{width: '60px'}} className="text-center">Menge</th>
                        <th>Info</th>
                        <th style={{width: '50px'}} className="text-center"><i className="bi bi-truck h3"></i></th>
                        <th style={{width: '50px'}} className="text-center"><i className="bi bi-wrench-adjustable-circle h3"></i></th>
                        <th style={{width: '50px'}} className="text-center"><i className="bi bi-plugin h3"></i></th>
                        <th style={{width: '50px'}} className="text-center"><i className="bi bi-recycle h3"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            formData.artikel.map((item, index) => {
                               //console.log(typeof(item.optData));
                                if(typeof(item.optData) === 'string') {
                                    item.optData2 = JSON.parse(item.optData);
                                } else {
                                    item.optData2 = item.optData;
                                }
                                return (
                                    <tr key={index}>
                                        <td>{item.artnr.substring(0, 3)} {item.artnr.substring(3, 8)} {item.artnr.substring(8, 11)}</td>
                                        <td>{item.name}</td>
                                        <td className="text-center">{item.menge}</td>
                                        <td>
                                           
                                            {
                                                item.optData2 !== null && item.optData2 !== undefined ?
                                                // eslint-disable-next-line array-callback-return
                                                Object.keys(item.optData2).map((celement, cindex) => {
                                                   //console.log("item", item.optData)
                                                    if(item.optData2 !== "null") {
                                                       //console.log("item.optData", item.optData2[celement])
                                                        if(typeof(item.optData2[celement]) === 'object') {
                                                            return (
                                                                <Row key={cindex}>
                                                                    <Col className='col-3 text-end'>{celement}:</Col>
                                                                    <Col> {item.optData2[celement].join(', ')}</Col>
                                                                </Row>
                                                            );
                                                        } else {
                                                            return (
                                                                <Row key={cindex}>
                                                                    <Col className='col-3 text-end'>{celement}:</Col>
                                                                    <Col> {item.optData2[celement]}</Col>
                                                                </Row>
                                                            );
                                                        }
                                                    }
                                                }) : ''
                                            }
                                            
                                        </td>
                                        <td className="text-center">

                                            <Form.Check 
                                                name="deliver" 
                                                type="checkbox" 
                                                defaultChecked={Number(item.deliver)} 
                                                disabled
                                            />
                                        </td>
                                        <td className="text-center">
                                            <Form.Check 
                                                name="setup" 
                                                type="checkbox" 
                                                defaultChecked={Number(item.setup)} 
                                                disabled
                                            />
                                        </td>
                                        <td className="text-center">
                                            <Form.Check 
                                                name="connect" 
                                                type="checkbox" 
                                                defaultChecked={Number(item.connect)} 
                                                disabled
                                            />
                                        </td>
                                        <td className="text-center">
                                            <Form.Check 
                                                name="dispose" 
                                                type="checkbox" 
                                                defaultChecked={Number(item.dispose)} 
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </Col>
        </Row>
    )

}
