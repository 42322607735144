import React, { Component } from 'react';


export default class CalendarTimeIndicator extends Component {

    constructor(props) {
        super(props);
    
        this.datesOpen = {
            1: null,
            2: null
        };
        
    }



    render() {
        const xHours = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
        //const xTime = [null,'calendar','vPrimary','vSecondary', 'vCity','0800','0830','h0900','h0930','h1000','h1030','h1100','h1130','h1200','h1230','nPrimary','nSecondary', 'nCity','h1300','h1330','h1400','h1430','h1500','h1530','h1600','h1630','h1700','h1730','h1800','h1830']
        const xTime = [null,'calendar','vPrimary','vSecondary', 'vCity','08:00','08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','nPrimary','nSecondary', 'nCity','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00']
        const currentTime = new Date();
        const open = {
            1: {
                '-240': 13,
                '-210': 13,
                '-180': 13,
                '-150': 13,
                '-120': 13,
                '-90': 13,
                '-60': 13,
                '-30': 13,
                0: 13,
                30: 12,
                60: 11,
                90: 10,
                120: 9,
                150: 8,
                180: 7,
                210: 6,
                240: 5,
                
            },
            2: {
                '-240': 30,
                '-210': 30,
                '-180': 30,
                '-150': 30,
                '-120': 30,
                '-90': 29,
                '-60': 28,
                '-30': 27,                
                0: 26,
                30: 25,
                60: 24,
                90: 23,
                120: 22,
                150: 21,
                180: 20,
                210: 19,
                240: 18,
            }
        };

        const itemData = this.props.itemData;
        const element = this.props.element;
        const calendarDate = this.props.CalendarDate;

        //console.log('CalendarTimeIndicator', xTime, datacalendar);
        this.datesOpen[1] = null;
        this.datesOpen[2] = null;


                return (
                    // eslint-disable-next-line array-callback-return
                    xHours.map((i, index) => {
                        switch(i) {

                            case 1:

                                if(itemData[1] && itemData[2] && itemData[1].dates_open && itemData[2].dates_open) {
                                    this.datesOpen[1] = itemData[1].dates_open;
                                    this.datesOpen[2] = itemData[2].dates_open;
                                } else if(itemData[1] && itemData[1].dates_open) {
                                    this.datesOpen[1] = itemData[1].dates_open;
                                } else if(itemData[2] && itemData[2].dates_open) {
                                    this.datesOpen[1] = itemData[2].dates_open;
                                }
                                break;

                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:

                                var thisTime1 = new Date(calendarDate + ' ' + xTime[i] + ':00');

                                //console.log(xTime[i] + ':00', xTime[i].replaceAll(":", ""))

                                //console.log(this.datesOpen[1])
                                if(currentTime < thisTime1 && i > 5 && i < 14 && this.datesOpen[1] !== null) {
                                    return (
                                        <div 
                                            key={index}
                                            className={i <= open[1][this.datesOpen[1]] ? "timeline timeline-booked" : "timeline timeline-free"}
                                            style={{gridRowStart: 'h' + xTime[i].replaceAll(":", ""), gridColumnStart: this.props.type === 'day' ? 'ct' + element.id + '' : 'ct' + calendarDate.replaceAll("-", "") + ''}}    
                                        ></div>
                                    )                           
                                } if(currentTime > thisTime1 && this.datesOpen[1] !== null) {
                                    return (
                                        <div 
                                            key={index}
                                            className="timeline timeline-time"
                                            style={{gridRowStart: 'h' + xTime[i].replaceAll(":", ""), gridColumnStart: this.props.type === 'day' ? 'ct' + element.id + '' : 'ct' + calendarDate.replaceAll("-", "") + ''}}    
                                        ></div>
                                    )                           
                                } 
                                break;

                            case 18:
                            case 19:
                            case 20:
                            case 21:
                            case 22:
                            case 23:
                            case 24:
                            case 25:
                            case 26:
                            case 27:
                            case 28:
                            case 29:
                            case 30:


                                var thisTime2 = new Date(calendarDate + ' ' + xTime[i] + ':00');

                                //console.log(xTime[i] + ':00', xTime[i].replaceAll(":", ""))

                                //console.log(this.datesOpen[1])
                                if(currentTime < thisTime2 && i > 18 && i < 27 && this.datesOpen[2] !== null) {
                                    return (
                                        <div 
                                            key={index}
                                            className={i <= open[2][this.datesOpen[2]] ? "timeline timeline-booked" : "timeline timeline-free"}
                                            style={{gridRowStart: 'h' + xTime[i].replaceAll(":", ""), gridColumnStart: this.props.type === 'day' ? 'ct' + element.id + '' : 'ct' + calendarDate.replaceAll("-", "") + ''}}    
                                        ></div>
                                    )                           
                                } if(currentTime > thisTime2 && this.datesOpen[2] !== null) {
                                    return (
                                        <div 
                                            key={index}
                                            className="timeline timeline-time"
                                            style={{gridRowStart: 'h' + xTime[i].replaceAll(":", ""), gridColumnStart: this.props.type === 'day' ? 'ct' + element.id + '' : 'ct' + calendarDate.replaceAll("-", "") + ''}}    
                                        ></div>
                                    )                           
                                } 
                                break;


                            default:
                                break;
                        }
                    })
                )

            

        
    }

}