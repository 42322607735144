import React, { Component } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';


export default class TableHeadDay extends Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);

        
    }



    render() {
        //console.log('TableHeadDay', this.props)
        const action = this.props.action;

        const days = [1,2,3,4,5,6,7];

        var thisDate = <Button className="me-2" size="sm" variant="success">Heute</Button>;

        if(action.now.date !== action.thisDate) {
            thisDate = <Button className="me-2" size="sm" variant="success" onClick={this.props.onChangesDay} value={action.now.date} >Heute</Button>;
        }
        if(localStorage.getItem('clienttype') === 'desktop') {

            return (
                <Card.Header tag="h5" id="day">
                    <div className='h4'>
                        <Badge>{this.props.badge}</Badge> {this.props.lable} {sessionStorage.getItem('dataKey')}
                        <div className="pull-right">
                            <Button variant="outline-primary" className="me-2" size="sm" onClick={this.props.onChangesDay} value={action.previousWeek} >KW{action.previousKW}</Button>
                            <ButtonGroup className="me-2" size="sm">
                                <Button  variant="outline-secondary" onClick={this.props.onChangesDay} value={action.previousDay} className="bi bi-caret-left-fill" />
        
                                {
                                    days.map((index) => {
                                        if(action.thisWeek[index].style.isOutline === true) {
                                            return (
                                                <Button variant={'outline-' + action.thisWeek[index].style.color} key={index} onClick={this.props.onChangesDay} value={action.thisWeek[index].date} >{action.thisWeek[index].day}, {action.thisWeek[index].short}</Button>
                                            )
                                        } else {
                                            return (
                                                <Button key={index} variant={action.thisWeek[index].style.color} >{action.thisWeek[index].day}, {action.thisWeek[index].short}</Button>
                                            )
                                        }
                                    })
                                }                    
                                <Button variant="outline-secondary" onClick={this.props.onChangesDay} value={action.nextDay} className="bi bi-caret-right-fill" />
                            </ButtonGroup>
                            <Button variant="outline-primary" className="me-2" size="sm" onClick={this.props.onChangesDay} value={action.nextWeek}>KW{action.nextKW}</Button>
        
                            {thisDate}
                        </div>
                    </div>
                </Card.Header>
            );
        } else {

            return (
                <Card.Header tag="h5" id="day">
                    {this.props.lable} {sessionStorage.getItem('dataKey')}
                    <div className="pull-right">
                        <Button variant="outline-primary" className="me-2" size="sm" color="primary" onClick={this.props.onChangesDay} value={action.previousWeek} >KW{action.previousKW}</Button>
                        <ButtonGroup className="me-2" size="sm">
                            <Button variant="outline-primary" onClick={this.props.onChangesDay} value={action.previousDay} className="bi bi-caret-left-fill" />
    
                            {
                                // eslint-disable-next-line array-callback-return
                                days.map((index) => {
                                    if(action.thisWeek[index].style.isOutline === true && index !== 7) {
                                        return (
                                            <Button variant={'outline-' + action.thisWeek[index].style.color}  key={index} color={action.thisWeek[index].style.color} onClick={this.props.onChangesDay} value={action.thisWeek[index].date} >{action.thisWeek[index].day}, {action.thisWeek[index].short}</Button>
                                        )
                                    } else if(index !== 7) {
                                        return (
                                            <Button key={index} color={action.thisWeek[index].style.color} >{action.thisWeek[index].day}, {action.thisWeek[index].short}</Button>
                                        )
                                    }
                                })
                            }                    
                            <Button variant="outline-primary" onClick={this.props.onChangesDay} value={action.nextDay} className="bi bi-caret-right-fill" />
                        </ButtonGroup>
                        <Button variant="outline-primary" className="me-2" size="sm" color="primary" onClick={this.props.onChangesDay} value={action.nextWeek}>KW{action.nextKW}</Button>
    
                        {thisDate}
                    </div>
                </Card.Header>
            );            
        }

    }

}