import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function ViewDebitor(props) {

    const formData = props.formData;

    return (
        <Row>
            <Col>
                <p className="form-control-lg mb-0 pb-0 pt-0">
                    {formData.salutation}<br />
                    {
                        formData.salutation === 'Firma' 
                            ? (<>{formData.firstname}<br />{formData.lastname}<br /></>) 
                            : (<>{formData.firstname} {formData.lastname}<br /></>)
                    }
                    {formData.address}<br />


                    {formData.zip} {formData.city}<br />
                </p>
            </Col>
            <Col>
                <p className="form-control-lg mb-0">
                    {
                        formData.etage !== '' 
                            ? (<p className="form-control-lg mb-0 pb-0 pt-0 ps-0">Etage: {formData.etage}</p>) 
                            : ''
                    }                    
                    {formData.telefon} <a type="button" className="btn btn-outline-success btn-sm" href={"tel:" + formData.telefon}>anrufen</a><br />
                    {formData.mobil} <a type="button" className="btn btn-outline-success btn-sm" href={"tel:" + formData.mobil}>anrufen</a><br />
                    {formData.mail}
                </p>
            </Col>
            <Col>
                <p className="form-control-lg mb-0">
                    Kunden Nr.: {formData.id}<br />

                    {
                        formData.sid !== ''
                            ? (<>SID: {formData.sid}<br /></>)
                            : ''
                    }
                    {
                        formData.sid !== ''
                            ? (<>Auftrag: {formData.vrva}<br /></>)
                            : ''
                    }                    
                </p>
            </Col>                        
        </Row>
    )

}
