import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';


export default function ViewDebitor(props) {

    const formData = props.formData;

    return (
        <Row>
            <h5>Fehler / Auftrag / Absprachen</h5>
            <Col md={12}>
                <Form.Control 
                    className="form-control-lg mb-0 pb-0 pt-0"
                    as="textarea" 
                    name="description"  
                    value={formData.description || formData.order} 
                    style={{minHeight: "100px",borderColor: "orange"}} 
                    disabled
                />
            </Col>
        </Row>
    )

}
