import React from 'react'; 
import { useLocation } from 'react-router-dom';

import Button from "react-bootstrap/Button";
import Container from 'react-bootstrap/Container';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavbarText from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";

export default function ExNavbar(props) {

  const location = useLocation();
  const { pathname } = location;

  //console.log("Navbar", location);
  var devText = '';
  var devColor = 'dark';
  if(localStorage.getItem('dev') === 'true') {
      devText = <NavbarText style={{paddingRight: '20px'}}>
                    <strong>TESTSYSTEM</strong>
                </NavbarText>;
      devColor = 'danger';
  }

  return (
    <div>

      <Navbar fixed="top" expand="lg"  bg={devColor} data-bs-theme="dark">
        <Container fluid>
          <Navbar.Brand href="/">ServicePlaner <img alt="logo" src="/logo192.png" style={{ height: 25, width: 25 }} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav  variant="underline" defaultActiveKey={pathname}>
              <Nav.Item>
                <Nav.Link href="/day" className='text-light'><i className="bi bi-calendar-date"></i>{' '}Tag</Nav.Link>
              </Nav.Item>
              {
                localStorage.getItem('clienttype') === 'desktop' 
                  ? <Nav.Item>
                      <Nav.Link href="/week" className='text-light'><i className="bi bi-calendar-week"></i>{' '}Woche</Nav.Link>
                    </Nav.Item> 
                  : ''
              }
              
              <Nav.Item>
                <Nav.Link href="/search" className='text-light'><i className="bi bi-calendar-plus"></i>{' '}Terminübersicht</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/list" className='text-light'><i className="bi bi-calendar-plus"></i>{' '}Terminliste</Nav.Link>
              </Nav.Item>
              <div><strong>{devText}</strong></div>
              {
                localStorage.getItem('clienttype') === 'desktop'
                ? <Nav.Item>
                    <Nav.Link href="/config" className='text-light'><i className="bi bi-calendar-plus"></i>{' '}Config1</Nav.Link>
                  </Nav.Item>
                : ''
              }

              <Nav.Item>
            <Nav.Link href="sumupmerchant://pay/1.0?amount=10.00&currency=EUR&affiliate-key=sup_afk_ldn33EHGwAX6Kzqr9XyDJdmEHzyfvjP7&title=My%20title&callbackfail=samplepaymentapp%3A%2F%2F&callbacksuccess=samplepaymentapp%3A%2F%2F" className='text-light'><i className="bi bi-calendar-plus"></i>{' '}TEST</Nav.Link>
            </Nav.Item>
            </Nav>
            
            <Nav variant='underline' className="ms-auto">
              <Form className="d-flex end-50">
                <Form.Control id="exampleSearch" name="search" placeholder="Auftragssuche" type="search" size="sm" onChange={props.onNavSearch} value={props.seachString} />
              </Form>
              <Button size="sm" onClick={props.onLogout}>Abmelden <i className="bi bi-box-arrow-right"></i></Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </div>
  );
}


/*


        <Navbar fixed="top" container="fluid" color={devColor} dark={true} expand={expand}>
          <NavbarBrand href="/">ServicePlaner <img
              alt="logo"
              src="/logo192.png"
              style={{
                height: 25,
                width: 25
              }}
              
        /> (<i className="bi bi-person"></i> {props.myService.MitarbeiterName})
      </NavbarBrand>
      <Collapse isOpen={!collapsed} navbar>
        <Nav navbar>

          <Nav.Item>
            <Nav.Link
              active
              href="/day"
            >
              <i className="bi bi-calendar-date"></i> Tag
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              active
              href="/week"

            >
              <i className="bi bi-calendar-week"></i> Woche
            </Nav.Link>    
          </Nav.Item>


          {<Nav.Item>
            <Nav.Link
              active
              href="/month"

            >
              <i className="bi bi-calendar-week"></i> Monat
            </Nav.Link>    
          </Nav.Item> }

          <Nav.Item>
          <Nav.Link
            active
            href="/search"
          >
            <i className="bi bi-calendar-plus"></i> Terminübersicht
          </Nav.Link>    
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            active
            href="/list"
          >
            <i className="bi bi-calendar-plus"></i> Terminliste
          </Nav.Link>    
        </Nav.Item>        

        </Nav>
      </Collapse>
      {devText}

      <NavbarText style={{paddingRight: '20px'}}>
            <Form.Control
            id="exampleSearch"
            name="search"
            placeholder="Auftragssuche"
            type="search"
            bsSize="sm"
            onChange={props.onNavSearch}
            value={props.seachString}
            />
          </NavbarText>
          <ButtonGroup>
            <NavbarToggler onClick={toggleNavbar} className="me-2" ><span className="navbar-toggler-icon"></span></NavbarToggler>
            <Button size="sm" onClick={props.onLogout}>Abmelden <i className="bi bi-box-arrow-right"></i></Button>
          </ButtonGroup>


        </Navbar>

*/