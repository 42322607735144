import React, {Component} from 'react';
import Form from 'react-bootstrap/Form';

export default class SelectCalendar extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.handleCalChange(this.props.listid, this.props.id, event.target.value);
    }

    render() {

        let bsSize = "sm";

        if(this.props.bsSize) {
            bsSize = this.props.bsSize;
        }
        const calendar = JSON.parse(sessionStorage.getItem('myCalendar'));

        return (
            
            <Form.Select
                size={bsSize}
                id="calendar"
                name="start"
                type="select"
                onChange={this.handleChange} 
                defaultValue={this.props.cal}
            >
                {
                    
                    calendar.map((cal, i) => {
                        return(<option key={i} value={cal.id} data-daytime={cal.daytime}>{cal.lable}</option>)
                    })
                }
            </Form.Select>        
        )

    }


}